import {SET_CURRENT_USER, CLEAR_CURRENT_USER} from "../constants/actionTypes";
import initialState from "./initialState";

export default function currentUserReducer(state = initialState.currentUser, action) {
  switch (action.type) {

    case SET_CURRENT_USER:
      return {...state,
        extuid: action.payload.extuid,
        username: action.payload.username,
        fullName: action.payload.fullName,
        phone: action.payload.phone,
        email: action.payload.email
      };

    case CLEAR_CURRENT_USER:
      return {...initialState.currentUser};

    default:
      return state;
  }
}
