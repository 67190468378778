import React from "react";
import {PropTypes} from "prop-types";
import {Alert} from "react-bootstrap";

const AlertDismissable = ({alertStyle, message, index, onDismiss}) => {
  return (
    <Alert bsStyle={alertStyle} onDismiss={() => onDismiss(index)}>
      <p>{message}</p>
    </Alert>
  )
}

AlertDismissable.propTypes = {
  alertStyle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default AlertDismissable;