import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";
import initialState from "../reducers/initialState";

function listAsArray(list) {
  return list.split(",").filter(x => x.length > 0);
}

function listsAsArrays(payload) {
  if (payload.hasOwnProperty("enrichedFieldNames")) {
    payload.enrichedFieldNames = listAsArray(payload.enrichedFieldNames)
  }

  if (payload.hasOwnProperty("exportableFieldNames")) {
    payload.exportableFieldNames = listAsArray(payload.exportableFieldNames);
  }

  if (payload.hasOwnProperty("resultFieldNames")) {
    payload.resultFieldNames = listAsArray(payload.resultFieldNames);
  }

  return payload;
}

function listsAsStrings(fields) {
  fields.enrichedFieldNames = fields.enrichedFieldNames.join(",");
  fields.exportableFieldNames = fields.exportableFieldNames.join(",");
  fields.resultFieldNames = fields.resultFieldNames.join(",");
}

export function getAvailableFields() {
  return function (dispatch) {
    dispatch({
      type: types.GET_AVAILABLE_FIELDS
    });

    return axios
    .get(`${api.AVAILABLE_FIELDS}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_AVAILABLE_FIELDS_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_AVAILABLE_FIELDS_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_AVAILABLE_FIELDS_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function getFields(resourceType, resourceId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_FIELDS
    });

    return axios.get(api.fields(resourceType, resourceId), apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        let transformedResponse = {...initialState.fields};
        if (res.data.length > 0) {
          transformedResponse = listsAsArrays(res.data[res.data.length - 1])
        }

        dispatch({
          type: types.GET_FIELDS_SUCCESS,
          payload: transformedResponse
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_FIELDS_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    }).catch(err => {
      dispatch({
        type: types.GET_FIELDS_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  };
}

export function saveFields(resourceType, resourceId, fields) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_FIELDS
    });

    let transformedFields = {...fields};
    listsAsStrings(transformedFields);

    return axios.post(`${api.fields(resourceType, resourceId)}`, transformedFields, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        let transformedResponse = initialState.fields;
        if (res.data.length > 0) {
          transformedResponse = listsAsArrays(res.data[res.data.length - 1])
        }

        dispatch({
          type: types.SAVE_FIELDS_SUCCESS,
          payload: transformedResponse
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_FIELDS_FAILURE,
          payload: fields,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_FIELDS_FAILURE,
        payload: fields,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}