export default {
  version: "",
  loading: {
    associations: {isLoading: false, status: ""},
    companies: {isLoading: false, status: ""},
    users: {isLoading: false, status: ""},
    availableFields: {isLoading: false, status: ""},
    fields: {isLoading: false, status: ""},
    availableFeatures: {isLoading: false, status: ""},
    features: {isLoading: false, status: ""},
    grants: {isLoading: false, status: ""},
    usages: {isLoading: false, status: ""},
  },
  session: {
    auth: !!localStorage.accessToken
  },
  currentUser: {
    extuid: "",
    username: "",
    fullName: "",
    phone: "",
    email: ""
  },
  availableRoles: [],
  alerts: [],
  users: [],
  user: {
    userInfo: {
      extuid: "",
      username: "",
      email: "",
      fullName: "",
      im: "",
      phone: ""
    },
    roles: []
  },
  companies: [],
  company: { 
    extid: "",
    name: "",
    description: ""
  },
  associations: [],
  association: {
    extid: "",
    name: "",
    description: ""
  },
  availableFields: [],
  fields: {
    id: "",
    associationId: "",
    enrichedFieldNames: [],
    exportableFieldNames: [],
    resultFieldNames: []
  },
  availableFeatures: {
    service: [],
    user: []
  },
  features: [],
  grants: [],
  grant: {
    extId: "",
    accessCount: 0,
    grantPeriod: "",
    renewUntil: "",
    renewable: true,
    rowCount: 0,
    serviceEvent: "",
    userEvent: "SEARCH",
    validFrom: "",
    validUntil: ""
  },
  usages: []
};
