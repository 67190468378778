import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/featureActions";
import {Row, Col, ButtonToolbar} from "react-bootstrap";
import FeaturesList from "../FeaturesList";
import FeatureForm from "../FeatureForm";
import IconButton from "../IconButton";
import Loader from "../Loader";

export class FeaturesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newFeature: false,
      resourceType: "",
      resourceId: ""
    };
  }

  componentWillMount() {
    this.props.actions.getAvailableFeatures();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resourceId != this.state.resourceId || 
      nextProps.resourceType != this.state.resourceType) {
      this.setState({
        resourceType: nextProps.resourceType,
        resourceId: nextProps.resourceId
      }, () => {
        if (this.state.resourceId && this.state.resourceType) {
          this.fetchFeatures();
        }
      }); 
    }

    if (nextProps.features) {
      this.setState({
        newFeature: false
      });
    }
  }

  fetchFeatures = () => {
    this.props.actions.getFeatures(
      this.state.resourceType,
      this.state.resourceId
    );
  }

  onNewFeature = () => {
    this.setState({newFeature: true}); 
  }

  onCancelNewFeature = () => {
    this.setState({newFeature: false});
  }

  onDelete = (feature) => {
    event.preventDefault();

    this.props.actions.deleteFeature(this.props.resourceType, this.props.resourceId, feature)
    .then(() => {
      this.fetchFeatures();
    });
  }

  onSave = (newFeature) => {
    event.preventDefault();

    this.props.actions.saveFeature(this.props.resourceType, this.props.resourceId, newFeature)
    .then(() => {
      this.fetchFeatures();
    });
  }

  render() {
    return (
      <div className="features-container">
        <Row>
          <Col xs={12}>
            <h3 className="bold">Features</h3>
          </Col>
        </Row>
        {this.props.loading.isLoading ? (
          <Row>
            <Col xs={12}>
              <Loader status={this.props.loading.status} />
            </Col>
          </Row>
        ) : (
          <div>
            <Row>
              <Col xs={12}>
                { !this.state.newFeature ? (
                  <ButtonToolbar className="actions">
                    <IconButton 
                        onClick={this.onNewFeature}
                        icon="plus"
                        btnText="New Feature Date Range" />
                  </ButtonToolbar>
                ) : null }
              </Col>
            </Row>
            <Row>
              { this.state.newFeature ? (
                <Col xs={12} md={4}>
                  <FeatureForm 
                    availableFeatures={this.props.availableFeatures}
                    onSave={this.onSave.bind(this)}
                    onCancel={this.onCancelNewFeature.bind(this)} />
                </Col>
              ) : null }
              <Col xs={12} md={this.state.newFeature ? 8 : 12}>
                { this.props.features.length > 0 ? (
                  <FeaturesList
                    features={this.props.features}
                    onDelete={this.onDelete.bind(this)} />
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

FeaturesContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.object.isRequired,
  availableFeatures: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.features,
    availableFeatures: state.availableFeatures,
    features: state.features
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturesContainer)