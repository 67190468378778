import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import qs from "qs";
// import * as apiUtils from "../utils/apiUtils";

export function loginUser(credentials) {
  return function(dispatch) {
    let data = {
      grant_type: "password",
      username: credentials.email,
      password: credentials.password,
      scope: "read write",
      client_id: "twg-trusted-client"
    };

    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        Authorization: "Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=="
      }
    };

    return axios
      .post(api.AUTHENTICATION, qs.stringify(data), config)
      .then(res => {
        if (res.data.authorities.split(",").includes("ROLE_ADMIN") ||
          res.data.authorities.split(",").includes("ROLE_DELADMIN")) {
          localStorage.setItem("accessToken", res.data.access_token);
          dispatch({ type: types.LOGIN_SUCCESS });

          return Promise.resolve();
        } else {
          dispatch({
            type: types.ACCESS_FORBIDDEN,
            error: "Your account is not authorized for this action."
          });
        }
      })
      .catch(err => {
        dispatch({
          type: types.LOGIN_FAILURE,
          error: "Bad credentials."
        });

        return Promise.reject(err);
      });
  };
}

export function logoutUser() {
  // TODO: Delete access token on backend, currently getting 401
  // axios.delete(api.AUTHENTICATION, apiUtils.getConfig()).then(res => {
  //   console.log(res);
  //   debugger;
  //   localStorage.removeItem("accessToken");
  //   return {type: types.LOGOUT};
  // }).catch(error => {
  //   debugger;
  //   throw(error);
  // });
  return function(dispatch) {
    localStorage.removeItem("accessToken");
    dispatch({ type: types.LOGOUT });
    return Promise.resolve();
  };
}
