import React from "react";
import {PropTypes} from "prop-types";
import {Col, Form, FormGroup, ButtonToolbar, Button, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment";

export class UsagesForm extends React.Component {
  constructor(props) {
    super(props);

    const newSearch = {
      groupby: this.props.groupby,
      fromDate: this.props.fromDate ? moment(this.props.fromDate) : moment().startOf("year"),
      toDate: this.props.toDate ? moment(this.props.toDate) : moment()
    };

    this.state = {
      search: newSearch
    };
  }

  onGroupByChange = (event) => {
    return this.setState({
      search: {...this.state.search,
        groupby: event
      }
    });
  }

  onDateChange = (momentValue, prop) => {
    return this.setState({
      search: {...this.state.search,
        [prop]: momentValue
      }
    });
  }

  onSearch = () => {
    const fromDate = this.state.search.fromDate ? this.state.search.fromDate.format("YYYY-MM-DD") : null;
    const toDate = this.state.search.toDate ? this.state.search.toDate.format("YYYY-MM-DD") : null;
    
    this.props.onSearch(
      this.state.search.groupby,
      fromDate,
      toDate
    );
  }

  render() {
    return (
      <Form className="usages-form card" horizontal onSubmit={this.onSearch.bind(this)}>
        <FormGroup controlId="formGroupBy">
          <Col xs={12}>
            <ButtonToolbar>
              <ToggleButtonGroup type="radio" name="groupby" value={this.state.search.groupby} onChange={this.onGroupByChange}>
                <ToggleButton bsStyle="success" key="summary" value="summary">
                  Summary
                </ToggleButton>
                <ToggleButton bsStyle="success" key="monthly" value="monthly">
                  Monthly
                </ToggleButton>
                <ToggleButton bsStyle="success" key="all" value="">
                  All
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Col>
        </FormGroup>

        <FormGroup controlId="formFromDate">
          <Col xs={12}>
            From Date
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="fromDate"
              value={this.state.search.fromDate} 
              onChange={(e) => this.onDateChange(e, "fromDate")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formToDate">
          <Col xs={12}>
            To Date
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="toDate"
              value={this.state.search.toDate} 
              onChange={(e) => this.onDateChange(e, "toDate")} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

UsagesForm.propTypes = {
  groupby: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UsagesForm;