import initialState from "./initialState";
import { 
  GET_VERSION
} from "../constants/actionTypes";

export default function versionReducer(state = initialState.version, action) {
  switch (action.type) {
    
    case GET_VERSION:
      return action.payload.versionId;

    default:
      return state;
  }
}
