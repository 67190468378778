import React from "react";
import {PropTypes} from "prop-types";
import {Row, Col, Table, ButtonToolbar} from "react-bootstrap";
import IconButton from "./IconButton";
import ReactFileReader from 'react-file-reader';

const UsersList = ({users, onCreate, onEdit, onDisable, onEnable, onUpload}) => {
  let userRows = users.map((user, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ButtonToolbar>
            <IconButton 
              onClick={() => onEdit(user.userInfo.extuid)}
              icon="edit"
              tooltipText="View/Edit" />
            
            { user.userInfo.enabled ?
              <IconButton 
              onClick={() => onDisable(user)}
              icon="user-slash"
              tooltipText="Disable User" />
            :
              <IconButton 
              onClick={() => onEnable(user)}
              icon="user-check"
              tooltipText="Enable User" />
            }
          </ButtonToolbar>
        </td>
        <td>{user.userInfo.username}</td>
        <td>{user.userInfo.fullName}</td>
        <td>{user.userInfo.email}</td>
        <td>{user.userInfo.phone}</td>
      </tr>
    )
  });

  return (
    <div className="users-list">
      <Row>
        <Col xs={12}>
          <h3 className="bold">Users</h3>

          <ButtonToolbar className="actions">
            <IconButton 
              onClick={() => onCreate()}
              icon="plus"
              btnText="New User" />

            <ReactFileReader 
              fileTypes={[".csv"]} 
              base64={true} 
              multipleFiles={false} 
              handleFiles={onUpload}>
              <IconButton 
                onClick={() => null}
                icon="file-upload"
                tooltipText="email, full name, im, phone"
                btnText="CSV Upload" />
            </ReactFileReader>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Actions</th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {userRows}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default UsersList;