import React from "react";
import {PropTypes} from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/sessionActions";
import {Form, FormGroup, Grid, Row, Col, FormControl, Image, Button} from "react-bootstrap";
import logo from "../../images/ai4jobs-color-logo.png";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {credentials: {email: "", password: ""}}

    if (this.props.isAuthenticated) {
      this.props.history.push("/associations");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated) {
      this.props.history.push("/associations");
    }
  }

  onChangeCredentials = (event) => {
    const field = event.target.name;
    const credentials = this.state.credentials;
    credentials[field] = event.target.value;
    return this.setState({credentials: credentials});
  }

  onLogin = (event) => {
    event.preventDefault();
    this.props.actions.loginUser(this.state.credentials);
  }

  render() {
    return (
      <div className="login-page">
        <Grid>
          <Row>
            <Col xs={6} xsOffset={3} className="center">
              <Image className="logo" src={logo} />
            </Col>
          </Row>
          <Row>
            <Col xs={6} xsOffset={3} className="center admin-text black">
              ADMIN
            </Col>
          </Row>
          <Row>
            <Col xs={10} xsOffset={1} md={6} mdOffset={3} className="login-box">
              <Form horizontal onSubmit={(e) => this.onLogin(e)}>
                <FormGroup controlId="formHorizontalEmail">
                  <Col sm={2}>
                    Email
                  </Col>
                  <Col sm={10}>
                    <FormControl type="email" placeholder="Email" name="email" onChange={this.onChangeCredentials} />
                  </Col>
                </FormGroup>

                <FormGroup controlId="formHorizontalPassword">
                  <Col sm={2}>
                    Password
                  </Col>
                  <Col sm={10}>
                    <FormControl type="password" placeholder="Password" name="password" onChange={this.onChangeCredentials} />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col smOffset={2} sm={10}>
                    <Button bsStyle="primary" type="submit">Sign In</Button>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));