export function getHeaders() {
  return {
    "Authorization": "Bearer " + localStorage.getItem("accessToken"),
    "Content-Type": "application/json"
  };
}

export function getConfig() {
  return {
    headers: getHeaders()
  };
}

export function getResponseError(res) {
  if (res.message) {
    return res.message.toString();
  }

  if (res.error) {
    return res.error.toString();
  }

  return "Something went wrong.";
}