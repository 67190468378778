import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function getGrants(resourceType, resourceId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_GRANTS
    });

    axios.get(api.grants(resourceType, resourceId), apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_GRANTS_SUCCESS,
          payload: res.data
        });
      } else {
        dispatch({
          type: types.GET_GRANTS_FAILURE,
          error: apiUtils.getResponseError(res)
        });
      }
    }).catch(err => {
      dispatch({
        type: types.GET_GRANTS_FAILURE,
        error: err.toString()
      });
    });
  };
}

export function saveGrant(resourceType, resourceId, grant) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_GRANT
    });

    return axios.post(api.grants(resourceType, resourceId), grant, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_GRANT_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_GRANT_FAILURE,
          payload: grant,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_GRANT_FAILURE,
        payload: grant,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function deleteGrant(resourceType, resourceId, grant) {
  return function (dispatch) {
    dispatch({
      type: types.DELETE_GRANT
    });

    const params = {
      data: {
        extId: resourceId,
        grantPeriod: grant.grantPeriod,
        userEvent: grant.userEvent,
        serviceEvent: grant.serviceEvent
      }
    };
    const config = {...apiUtils.getConfig(), ...params};

    return axios.delete(api.grants(resourceType, resourceId), config)
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.DELETE_GRANT_SUCCESS,
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.DELETE_GRANT_FAILURE,
          payload: grant,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.DELETE_GRANT_FAILURE,
        payload: grant,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}