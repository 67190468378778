import React from "react";
import {PropTypes} from "prop-types";
import {Table, ButtonToolbar, Label} from "react-bootstrap";
import IconButton from "./IconButton";
import moment from "moment";

const FeaturesList = ({features, onDelete}) => {
  const eventsList = (featureEvents) => {
    return featureEvents.map((featureEvent) => {
      return (
        <Label key={featureEvent} bsStyle="success">
          {featureEvent}
        </Label>
      )
    })
  }
  let featureRows = features.map((feature, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ButtonToolbar>
            <IconButton 
              onClick={() => onDelete(feature)}
              icon="trash"
              tooltipText="Delete" />
          </ButtonToolbar>
        </td>
        <td>{moment(feature.activeFrom).format("YYYY-MM-DD HH:mm:ss")}</td>
        <td>{moment(feature.activeTo).format("YYYY-MM-DD HH:mm:ss")}</td>
        <td>{eventsList(feature.authorizedServiceEvents)}</td>
        <td>{eventsList(feature.authorizedUserEvents)}</td>
      </tr>
    )
  });

  return (
    <div className="features-list">
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Actions</th>
            <th>Active From</th>
            <th>Active To</th>
            <th>Service Events</th>
            <th>User Events</th>
          </tr>
        </thead>
        <tbody>
          {featureRows}
        </tbody>
      </Table>
    </div>
  );
}

FeaturesList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired
};

export default FeaturesList;