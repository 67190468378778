import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/fieldActions";
import {Row, Col} from "react-bootstrap";
import FieldsForm from "../FieldsForm";
import Loader from "../Loader";

export class FieldsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      resourceType: "",
      resourceId: ""
    };
  }

  componentWillMount() {
    this.props.actions.getAvailableFields();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resourceId != this.state.resourceId || 
      nextProps.resourceType != this.state.resourceType) {
      this.setState({
        resourceType: nextProps.resourceType,
        resourceId: nextProps.resourceId
      }, () => {
        if (this.state.resourceId && this.state.resourceType) {
          this.fetchFields();
        }
      }); 
    }

    if (nextProps.fields) {
      this.setState({
        fields: {...nextProps.fields}
      });
    }
  }

  fetchFields = () => {
    this.props.actions.getFields(
      this.state.resourceType,
      this.state.resourceId
    );
  }

  onSave = (fields) => {
    event.preventDefault();

    this.props.actions.saveFields(this.props.resourceType, this.props.resourceId, fields)
    .then(() => {
      this.fetchFields();
    });
  }

  render() {
    return (
      <div className="fields-container">
        <Row>
          <Col xs={12}>
            <h3 className="bold">Fields</h3>
            {this.props.loading.isLoading ? (
              <Loader status={this.props.loading.status} />
            ) : (
              <FieldsForm 
                fields={this.props.fields}
                availableFields={this.props.availableFields}
                onSave={this.onSave.bind(this)}
                onCancel={this.fetchFields.bind(this)} />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

FieldsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.fields,
    availableFields: state.availableFields,
    fields: state.fields
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldsContainer)