import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/userActions";
import * as currentUserActions from "../../actions/currentUserActions";
import {Grid, Row, Col} from "react-bootstrap";
import UserForm from "../UserForm";
import User from "../User";
import Loader from "../Loader";
import FieldsContainer from "./FieldsContainer";
import FeaturesContainer from "./FeaturesContainer";
import GrantsContainer from "./GrantsContainer";
import UsagesContainer from "./UsagesContainer";

export class UserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {...props.user},
      associationId: this.props.match.params.associationId,
      companyId: this.props.match.params.companyId,
      userId: this.props.match.params.userId
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.actions.getUser(this.state.associationId, this.state.companyId, this.state.userId);
    this.props.currentUserActions.getCurrentUser()
    .then((currentUser) => {
      this.props.actions.getAvailableRoles(currentUser.extuid);
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({
        user: {...nextProps.user}
      });
    }
  }

  onBack = () => {
    this.props.history.push(`/associations/${this.state.associationId}/companies/${this.state.companyId}`)
  }

  toggleEditUser = () => {
    this.setState({editingUser: !this.state.editingUser});
  }
  
  sendPasswordResetEmail = () => {
    this.props.actions.sendPasswordResetInstructions(this.props.user.userInfo.email);
  }

  render() {
    return (
      <div className="user-container resource-container">
        <Grid fluid>
          <Row className="section">
            <Col xs={12}>
              <div className="link-toolbar">
                <a href="#" onClick={() => this.onBack()}>
                  Back to Company
                </a>
              </div>
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12} md={6}>
              <h2 className="bold">User</h2>
              {this.props.loadingUsers.isLoading ? (
                <Loader status={this.props.loadingUsers.status} />
              ) : (
                <div>
                  {this.state.editingUser ? (
                    <UserForm 
                      actions={this.props.actions}
                      user={this.props.user}
                      availableRoles={this.props.availableRoles}
                      associationId={this.state.associationId}
                      companyId={this.state.companyId}
                      onCancel={this.toggleEditUser.bind(this)} />
                  ): (
                    <User
                      user={this.props.user}
                      onEdit={this.toggleEditUser.bind(this)}
                      onSendPasswordResetEmail={this.sendPasswordResetEmail.bind(this)} />
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <UsagesContainer
                resourceType="user"
                resourceIds={[this.state.associationId, this.state.companyId, this.state.userId]} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FeaturesContainer
                resourceType="user"
                resourceId={this.state.userId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <GrantsContainer
                resourceType="user"
                resourceId={this.state.userId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FieldsContainer
                resourceType="user"
                resourceId={this.state.userId} />
            </Col>
          </Row>
        </Grid>  
      </div>
    );
  }
}

UserContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  currentUserActions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired
    })
  }),
  loadingUsers: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

function mapStateToProps(state) {
  return {
    loadingUsers: state.loading.users,
    user: state.user,
    availableRoles: state.availableRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    currentUserActions: bindActionCreators(currentUserActions, dispatch),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContainer));