import initialState from "./initialState";
import { 
  GET_AVAILABLE_FIELDS,
  GET_AVAILABLE_FIELDS_SUCCESS,
  GET_AVAILABLE_FIELDS_FAILURE,
 } from "../constants/actionTypes";

export default function availableFieldsReducer(state = initialState.availableFields, action) {
  switch (action.type) {
    case GET_AVAILABLE_FIELDS:
    case GET_AVAILABLE_FIELDS_FAILURE:
      return [];

    case GET_AVAILABLE_FIELDS_SUCCESS:
      return action.payload.allFields;

    default:
      return state;
  }
}
