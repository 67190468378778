import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";

export class Footer extends React.Component {
  onTermsClick = () => {
    this.props.history.push("/terms");
  }

  render() {
    return (
      <div className="footer">
        <ul>
          <li>&copy; Copyright 2018, ThisWay Global LLC</li>
        </ul>
      </div>
    );
  }
}

Footer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default withRouter(connect(
  null,
  null
)(Footer));