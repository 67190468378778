import axios from "axios";
import * as types from "../constants/actionTypes";

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response) {
        if ([401, 403].includes(error.response.status)) {
          localStorage.removeItem("accessToken");
          store.dispatch({
            type: types.LOGOUT
          });

          if (error.response.status === 403) {
            store.dispatch({
              type: types.ACCESS_FORBIDDEN,
              error: "Your account is not authorized for this action."
            });
          }

          return Promise.reject(getErrorResponse(error));
        } else {
          return Promise.reject(getErrorResponse(error)); 
        }
      } else {
        return Promise.reject(getErrorResponse(error));
      }
    });
  }
};

function getErrorResponse(error) {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message.toString();
      }

      if (error.response.data.body) {
        if (error.response.data.body.errorCode) {
          return error.response.data.body.errorCode.toString();
        }
        return error.response.data.body.toString();
      }

      if (error.response.data.error) {
        return error.response.data.error.toString();
      }

      return error.response.data.toString();
    }
    return error.toString();
  } else {
    return error.toString();
  }
}