import initialState from "./initialState";
import { find, matchesProperty } from "lodash-es";
import {
  ACCESS_FORBIDDEN,
  CLEAR_ALERT,
  LOGIN_FAILURE,
  GET_AVAILABLE_ROLES_FAILURE,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_FAILURE,
  GET_USERS_FAILURE,
  GET_USER_FAILURE,
  SAVE_USER_FAILURE,
  DELETE_USER_FAILURE,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_FAILURE,
  SAVE_COMPANY_FAILURE,
  DELETE_COMPANY_FAILURE,
  GET_ASSOCIATIONS_FAILURE,
  GET_ASSOCIATION_FAILURE,
  SAVE_ASSOCIATION_FAILURE,
  DELETE_ASSOCIATION_FAILURE,
  GET_AVAILABLE_FIELDS_FAILURE,
  GET_FIELDS_FAILURE,
  SAVE_FIELDS_FAILURE,
  GET_AVAILABLE_FEATURES_FAILURE,
  GET_FEATURES_FAILURE,
  SAVE_FEATURE_FAILURE,
  DELETE_FEATURE_FAILURE,
  GET_GRANTS_FAILURE,
  GET_GRANT_FAILURE,
  SAVE_GRANT_FAILURE,
  DELETE_GRANT_FAILURE,
  GET_USAGES_FAILURE
} from "../constants/actionTypes";

export default function alertsReducer(state = initialState.alerts, action) {
  switch (action.type) {

    case CLEAR_ALERT:
      return state.filter((item, index) => index !== action.index)

    case ACCESS_FORBIDDEN:
    case LOGIN_FAILURE:
    case GET_AVAILABLE_ROLES_FAILURE:
    case SEND_PASSWORD_RESET_FAILURE:
    case GET_USERS_FAILURE:
    case GET_USER_FAILURE:
    case SAVE_USER_FAILURE:
    case DELETE_USER_FAILURE:
    case GET_COMPANIES_FAILURE:
    case GET_COMPANY_FAILURE:
    case SAVE_COMPANY_FAILURE:
    case DELETE_COMPANY_FAILURE:
    case GET_ASSOCIATIONS_FAILURE:
    case GET_ASSOCIATION_FAILURE:
    case SAVE_ASSOCIATION_FAILURE:
    case DELETE_ASSOCIATION_FAILURE:
    case GET_AVAILABLE_FIELDS_FAILURE:
    case GET_FIELDS_FAILURE:
    case SAVE_FIELDS_FAILURE:
    case GET_AVAILABLE_FEATURES_FAILURE:
    case GET_FEATURES_FAILURE:
    case SAVE_FEATURE_FAILURE:
    case DELETE_FEATURE_FAILURE:
    case GET_GRANTS_FAILURE:
    case GET_GRANT_FAILURE:
    case SAVE_GRANT_FAILURE:
    case DELETE_GRANT_FAILURE:
    case GET_USAGES_FAILURE: {
      const message = action.error || "Something went wrong."

      // Add the alert if it's not already in the array
      if (find(state, matchesProperty("message", message))) {
        return state;
      } else {
        return [
          ...state,
          {
            style: "danger",
            message: message
          }
        ];
      }
    }

    case SEND_PASSWORD_RESET_SUCCESS: {
      const message = action.message || "Success";

      // Add the alert if it's not already in the array
      if (find(state, matchesProperty("message", message))) {
        return state;
      } else {
        return [
          ...state,
          {
            style: "success",
            message: message
          }
        ];
      }
    }
      
    default:
      return state;
  }
}