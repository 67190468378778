import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function clearCompany() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_COMPANY
    });
  }
}

export function getCompanies(associationId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_COMPANIES
    });

    return axios.get(api.companies(associationId), apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_COMPANIES_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_COMPANIES_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    }).catch(err => {
      dispatch({
        type: types.GET_COMPANIES_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  };
}

export function getCompany(associationId, companyId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_COMPANY
    });

    return axios
    .get(`${api.companies(associationId)}/${companyId}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_COMPANY_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_COMPANY_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_COMPANY_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function createCompany(associationId, company) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_COMPANY
    });

    return axios.post(`${api.companies(associationId)}`, company, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_COMPANY_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_COMPANY_FAILURE,
          payload: company,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_COMPANY_FAILURE,
        payload: company,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function updateCompany(associationId, company) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_COMPANY
    });

    return axios.put(`${api.companies(associationId)}/${company.extid}`, company, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_COMPANY_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_COMPANY_FAILURE,
          payload: company,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_COMPANY_FAILURE,
        payload: company,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function deleteCompany(associationId, company) {
  return function () {
    return axios.delete(`${api.companies(associationId)}/${company.extid}`, apiUtils.getConfig());
  }
}