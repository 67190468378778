import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function clearUser() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_USER
    });
  }
}

export function getUsers(associationId, companyId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_USERS
    });

    return axios.get(api.users(associationId, companyId), apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_USERS_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_USERS_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    }).catch(err => {
      dispatch({
        type: types.GET_USERS_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  };
}

export function getUser(associationId, companyId, userId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_USER
    });

    return axios
    .get(`${api.users(associationId, companyId)}/${userId}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_USER_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_USER_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_USER_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function createUser(associationId, companyId, user) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_USER
    });

    return axios.post(`${api.users(associationId, companyId)}`, user, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_USER_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_USER_FAILURE,
          payload: user,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_USER_FAILURE,
        payload: user,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function updateUser(associationId, companyId, user) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_USER
    });

    return axios.put(`${api.users(associationId, companyId)}/${user.userInfo.extuid}`, user, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_USER_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_USER_FAILURE,
          payload: user,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_USER_FAILURE,
        payload: user,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function deleteUser(associationId, companyId, user) {
  return function () {
    return axios.delete(`${api.users(associationId, companyId)}/${user.userInfo.extuid}`, apiUtils.getConfig());
  }
}

export function getAvailableRoles(userId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_AVAILABLE_ROLES
    });

    return axios
    .get(`${api.availableRoles(userId)}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_AVAILABLE_ROLES_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_AVAILABLE_ROLES_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_AVAILABLE_ROLES_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function sendPasswordResetInstructions(email) {
  return function (dispatch) {
    dispatch({
      type: types.SEND_PASSWORD_RESET
    });

    return axios.post(api.PASSWORD_RESET_EMAIL, {email: email}, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SEND_PASSWORD_RESET_SUCCESS,
          message: `Password reset sent to ${email}`,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SEND_PASSWORD_RESET_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SEND_PASSWORD_RESET_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}