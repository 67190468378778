import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/sessionActions";
import * as currentUserActions from "../actions/currentUserActions";
import * as versionActions from "../actions/versionActions";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { version } from '../../package.json';

export class HeaderNavbar extends React.Component {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.currentUserActions.getCurrentUser()
      this.props.versionActions.getVersion();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isAuthenticated && nextProps.isAuthenticated) {
      this.props.currentUserActions.getCurrentUser()
      this.props.versionActions.getVersion();
    }
  }

  onLogout = event => {
    event.preventDefault();
    this.props.actions.logoutUser().then(() => {
      this.props.currentUserActions.clearCurrentUser();
    });
  };

  render() {
    return this.props.isAuthenticated ? (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            AI4JOBS Admin
            <span className="version">
              API: {this.props.version}
            </span>
            <span className="version">
              UI: {version}
            </span>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer to="/associations">
              <NavItem eventKey={1} href="#">
                Associations
              </NavItem>
            </LinkContainer>
          </Nav>
          <Nav pullRight>
            <NavItem
              eventKey={1}
              href="https://www.thiswayglobal.com/contact-us/">
              Contact Us
            </NavItem>
            <NavDropdown 
              eventKey={2}
              title={this.props.currentUser.fullName}
              id="basic-nav-dropdown">
              <MenuItem eventKey={2.1} onClick={this.onLogout}>
                Sign Out
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    ) : null;
  }
}

HeaderNavbar.propTypes = {
  actions: PropTypes.object.isRequired,
  currentUserActions: PropTypes.object.isRequired,
  versionActions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.auth,
    currentUser: state.currentUser,
    version: state.version
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    currentUserActions: bindActionCreators(currentUserActions, dispatch),
    versionActions: bindActionCreators(versionActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false } // This fixes the "active" class state for nav bar links
)(HeaderNavbar);
