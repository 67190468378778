import initialState from "./initialState";
import { 
  GET_FIELDS,
  GET_FIELDS_SUCCESS,
  GET_FIELDS_FAILURE,
  SAVE_FIELDS_SUCCESS,
  SAVE_FIELDS_FAILURE,
} from "../constants/actionTypes";

export default function fieldsReducer(state = initialState.fields, action) {
  switch (action.type) {
    case GET_FIELDS:
      return {...initialState.fields};

    case GET_FIELDS_SUCCESS:
    case SAVE_FIELDS_SUCCESS:
      return action.payload;
    
    case GET_FIELDS_FAILURE:
    case SAVE_FIELDS_FAILURE:
      return state;

    default:
      return state;
  }
}
