import React from "react";
import {PropTypes} from "prop-types";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import Icon from "./Icon";

const IconButton = ({onClick, icon, tooltipText, btnText, btnStyle }) => {
  const tooltip = (
    <Tooltip id="tooltip" className="tooltip">
      {tooltipText}
    </Tooltip>
  );

  const bsStyle = btnStyle || "primary";

  const button = (
    <Button bsStyle={bsStyle} className="collapse-btn icon-button" onClick={() => onClick()}>
      <span><Icon icon={icon} /></span>

      { btnText ? (
        <span style={{ marginLeft: "4px" }}>{btnText}</span>
      ) : null
      }
      
    </Button>
  );

  return tooltipText ? (
    <OverlayTrigger key={"open"} placement="top" overlay={tooltip}>
      {button}
    </OverlayTrigger>
  ) : (
    <div className="non-overlay-btn">
      {button}
    </div>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  btnText: PropTypes.string,
  btnStyle: PropTypes.string
};

export default IconButton;