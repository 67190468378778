import React from "react";
import {PropTypes} from "prop-types";
import {Col, Form, FormGroup, FormControl, ToggleButtonGroup, ToggleButton, ButtonToolbar, Button} from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment";
import initialState from "../reducers/initialState";
import { startCase } from "lodash-es";

const PERIODS = ["DAY", "MONTH", "YEAR"];

export class GrantForm extends React.Component {
  constructor(props) {
    super(props);

    const now = moment();
    const oneYearFromNow = moment(now).add(1, "year")

    const newGrant = {...initialState.grant}
    newGrant.serviceEvent = this.props.availableFeatures.service[0];
    newGrant.extId = this.props.resourceId;
    newGrant.grantPeriod = "YEAR";
    newGrant.validFrom = now.clone();
    newGrant.validUntil = oneYearFromNow.clone();
    newGrant.renewUntil = oneYearFromNow.clone();

    this.state = {
      grant: newGrant
    };
  }

  serviceEventOptions = () => {
    return this.props.availableFeatures.service.map((serviceEvent) => {
      return (
        <ToggleButton bsStyle="success" key={serviceEvent} value={serviceEvent}>
          {startCase(serviceEvent)}
        </ToggleButton>
      )
    });
  }

  onServiceEventChange = (event) => {
    return this.setState({
      grant: {...this.state.grant,
        serviceEvent: event
      }
    });
  }

  onPeriodChange = (event) => {
    return this.setState({
      grant: {...this.state.grant,
        grantPeriod: event
      }
    });
  }

  onInputChange = (event) => {
    const field = event.target.name;
    const grant = {...this.state.grant};
    grant[field] = event.target.value;

    if (event.target.type === "number") {
      grant[field] = parseInt(event.target.value);
    }

    return this.setState({
      grant: grant
    });
  }

  onDateChange = (momentValue, prop) => {
    return this.setState({
      grant: {...this.state.grant,
        [prop]: momentValue
      }
    });
  }

  checkForNull = (prop) => {
    if (this.state.grant[prop] == null) {
      return "error"
    }

    return null;
  }

  isFormValid = () => {
    return (
      this.state.grant.validFrom != null &&
      this.state.grant.validUntil != null &&
      this.state.grant.renewUntil != null
    );
  }

  onSave = () => {
    // Change moment fields to ISO strings as expected by the api
    const apiGrant = {...this.state.grant,
      validFrom: this.state.grant.validFrom.toISOString(),
      validUntil: this.state.grant.validUntil.toISOString(),
      renewUntil: this.state.grant.renewUntil.toISOString(),
    };

    if (apiGrant.serviceEvent != "ENRICH" && apiGrant.serviceEvent != "EXPORT") {
      delete apiGrant["rowCount"];
    }

    this.props.onSave(apiGrant);
  }

  render() {
    const periods = PERIODS.map((period) => {
      return (
        <ToggleButton bsStyle="success" key={period} value={period}>
          {period}
        </ToggleButton>
      )
    });

    return (
      <Form className="grant-form card" horizontal onSubmit={this.onSave.bind(this)}>
        <FormGroup controlId="formServiceEventType">
          <Col xs={12}>
            <div>Service Event:</div>
            <ButtonToolbar>
              <ToggleButtonGroup type="radio" name="serviceEvent" value={this.state.grant.serviceEvent} onChange={this.onServiceEventChange}>
                {this.serviceEventOptions()}
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Col>
        </FormGroup>

        <FormGroup controlId="formPeriods">
          <Col xs={12}>
            <div>Period:</div>
            <ButtonToolbar>
              <ToggleButtonGroup type="radio" name="periods" value={this.state.grant.grantPeriod} onChange={this.onPeriodChange}>
                {periods}
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Col>
        </FormGroup>

        <FormGroup controlId="formCounts">
          <Col xs={12} sm={6}>
            Access Count:
            <FormControl 
              type="number"
              placeholder=""
              name="accessCount"
              defaultValue={this.state.grant.accessCount} 
              onChange={this.onInputChange.bind(this)} />
          </Col>

          { (this.state.grant.serviceEvent == "ENRICH" || this.state.grant.serviceEvent == "EXPORT") ? (
            <div>
              <Col xs={12} sm={6}>
                Row Count:
                <FormControl 
                  type="number"
                  placeholder=""
                  name="rowCount"
                  defaultValue={this.state.grant.rowCount} 
                  onChange={this.onInputChange.bind(this)} />
              </Col>
            </div>
          ) : null }
        </FormGroup>

        <FormGroup controlId="formValidFrom"
          validationState={this.checkForNull("validFrom")}>

          <Col xs={12}>
            Valid From
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="validFrom"
              value={this.state.grant.validFrom} 
              onChange={(e) => this.onDateChange(e, "validFrom")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formValidUntil"
          validationState={this.checkForNull("validUntil")}>
          <Col xs={12}>
            Valid Until
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="validUntil"
              value={this.state.grant.validUntil} 
              onChange={(e) => this.onDateChange(e, "validUntil")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formRenewUntil"
          validationState={this.checkForNull("renewUntil")}>
          <Col xs={12}>
            Renew Until
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="renewUntil"
              value={this.state.grant.renewUntil} 
              onChange={(e) => this.onDateChange(e, "renewUntil")} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit" disabled={!this.isFormValid()}>Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

GrantForm.propTypes = {
  resourceId: PropTypes.string.isRequired,
  availableFeatures: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default GrantForm;