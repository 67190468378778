export const GET_VERSION = "GET_VERSION"

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const GET_AVAILABLE_ROLES = "GET_AVAILABLE_ROLES";
export const GET_AVAILABLE_ROLES_SUCCESS = "GET_AVAILABLE_ROLES_SUCCESS";
export const GET_AVAILABLE_ROLES_FAILURE = "GET_AVAILABLE_ROLES_FAILURE";

export const ACCESS_FORBIDDEN = "ACCESS_FORBIDDEN";

export const CLEAR_ALERT = "CLEAR_ALERT";

export const CLEAR_USER = "CLEAR_USER";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_FAILURE = "SEND_PASSWORD_RESET_FAILURE";

export const CLEAR_ASSOCIATION = "CLEAR_ASSOCIATION";
export const GET_ASSOCIATIONS = "GET_ASSOCIATIONS";
export const GET_ASSOCIATIONS_SUCCESS = "GET_ASSOCIATIONS_SUCCESS";
export const GET_ASSOCIATIONS_FAILURE = "GET_ASSOCIATIONS_FAILURE";
export const GET_ASSOCIATION = "GET_ASSOCIATION";
export const GET_ASSOCIATION_SUCCESS = "GET_ASSOCIATION_SUCCESS";
export const GET_ASSOCIATION_FAILURE = "GET_ASSOCIATION_FAILURE";
export const SAVE_ASSOCIATION = "SAVE_ASSOCIATION";
export const SAVE_ASSOCIATION_SUCCESS = "SAVE_ASSOCIATION_SUCCESS";
export const SAVE_ASSOCIATION_FAILURE = "SAVE_ASSOCIATION_FAILURE";
export const DELETE_ASSOCIATION = "DELETE_ASSOCIATION";
export const DELETE_ASSOCIATION_SUCCESS = "DELETE_ASSOCIATION_SUCCESS";
export const DELETE_ASSOCIATION_FAILURE = "DELETE_ASSOCIATION_FAILURE";

export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";
export const SAVE_COMPANY = "SAVE_COMPANY";
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_FAILURE = "SAVE_COMPANY_FAILURE";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";


export const GET_AVAILABLE_FIELDS = "GET_AVAILABLE_FIELDS";
export const GET_AVAILABLE_FIELDS_SUCCESS = "GET_AVAILABLE_FIELDS_SUCCESS";
export const GET_AVAILABLE_FIELDS_FAILURE = "GET_AVAILABLE_FIELDS_FAILURE";

export const GET_FIELDS = "GET_FIELDS";
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS";
export const GET_FIELDS_FAILURE = "GET_FIELDS_FAILURE";
export const SAVE_FIELDS = "SAVE_FIELDS";
export const SAVE_FIELDS_SUCCESS = "SAVE_FIELDS_SUCCESS";
export const SAVE_FIELDS_FAILURE = "SAVE_FIELDS_FAILURE";

export const GET_AVAILABLE_FEATURES = "GET_AVAILABLE_FEATURES";
export const GET_AVAILABLE_FEATURES_SUCCESS = "GET_AVAILABLE_FEATURES_SUCCESS";
export const GET_AVAILABLE_FEATURES_FAILURE = "GET_AVAILABLE_FEATURES_FAILURE";

export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_FEATURES_FAILURE = "GET_FEATURES_FAILURE";
export const SAVE_FEATURE = "SAVE_FEATURE";
export const SAVE_FEATURE_SUCCESS = "SAVE_FEATURE_SUCCESS";
export const SAVE_FEATURE_FAILURE = "SAVE_FEATURE_FAILURE";
export const DELETE_FEATURE = "DELETE_FEATURE";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
export const DELETE_FEATURE_FAILURE = "DELETE_FEATURE_FAILURE";

export const GET_GRANTS = "GET_GRANTS";
export const GET_GRANTS_SUCCESS = "GET_GRANTS_SUCCESS";
export const GET_GRANTS_FAILURE = "GET_GRANTS_FAILURE";
export const GET_GRANT = "GET_GRANT";
export const GET_GRANT_SUCCESS = "GET_GRANT_SUCCESS";
export const GET_GRANT_FAILURE = "GET_GRANT_FAILURE";
export const SAVE_GRANT = "SAVE_GRANT";
export const SAVE_GRANT_SUCCESS = "SAVE_GRANT_SUCCESS";
export const SAVE_GRANT_FAILURE = "SAVE_GRANT_FAILURE";
export const DELETE_GRANT = "DELETE_GRANT";
export const DELETE_GRANT_SUCCESS = "DELETE_GRANT_SUCCESS";
export const DELETE_GRANT_FAILURE = "DELETE_GRANT_FAILURE";

export const GET_USAGES = "GET_USAGES";
export const GET_USAGES_SUCCESS = "GET_USAGES_SUCCESS";
export const GET_USAGES_FAILURE = "GET_USAGES_FAILURE";