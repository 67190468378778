import React from "react";
import {PropTypes} from "prop-types";
import {Col, Form, FormGroup, ButtonToolbar, Button} from "react-bootstrap";
import { DatePicker, Checkbox } from "antd";
import moment from "moment";

export class FeatureForm extends React.Component {
  constructor(props) {
    super(props);

    const now = moment();
    const oneYearFromNow = moment(now).add(1, "year")

    const newFeature = {
      activeFrom: now,
      activeTo: oneYearFromNow,
      authorizedServiceEvents: [],
      authorizedUserEvents: [],
      enabled: true
    };

    this.state = {
      feature: newFeature
    };
  }

  onDateChange = (momentValue, prop) => {
    this.setState({
      feature: {...this.state.feature,
        [prop]: momentValue
      }
    });
  }

  onCheckChange = (checkedList, type) => {
    this.setState({
      feature: { ...this.state.feature, 
        [type]: checkedList
      }
    });
  }

  onSave = () => {
    // Change moment fields to ISO strings as expected by the api
    const apiFeature = {...this.state.feature,
      activeFrom: this.state.feature.activeFrom.toISOString(),
      activeTo: this.state.feature.activeTo.toISOString()
    };

    this.props.onSave(apiFeature);
  }

  render() {
    return (
      <Form className="feature-form card" horizontal onSubmit={this.onSave.bind(this)}>
        <FormGroup controlId="formActiveFrom">
          <Col xs={12}>
            From
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="activeFrom"
              value={this.state.feature.activeFrom} 
              onChange={(e) => this.onDateChange(e, "activeFrom")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formActiveTo">
          <Col xs={12}>
            To
          </Col>
          <Col xs={12}>
            <DatePicker 
              name="activeTo"
              value={this.state.feature.activeTo} 
              onChange={(e) => this.onDateChange(e, "activeTo")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formServiceEvents">
          <Col xs={12}>
            Service Events
          </Col>
          <Col xs={12}>
            <Checkbox.Group 
              name="serviceEvents"
              options={this.props.availableFeatures.service}
              value={this.state.feature.authorizedServiceEvents} 
              onChange={(e) => this.onCheckChange(e, "authorizedServiceEvents")} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formUserEvents">
          <Col xs={12}>
            User Events
          </Col>
          <Col xs={12}>
            <Checkbox.Group 
              name="userEvents"
              options={this.props.availableFeatures.user}
              value={this.state.feature.authorizedUserEvents} 
              onChange={(e) => this.onCheckChange(e, "authorizedUserEvents")} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

FeatureForm.propTypes = {
  availableFeatures: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default FeatureForm;