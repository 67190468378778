import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

function getApiEndpoint(resourceType, resourceIds) {
  switch (resourceType) {
    case "association":
      return api.associationUsages(resourceIds[0]);
    case "company":
      return api.companyUsages(resourceIds[0], resourceIds[1]);
    case "user":
      return api.userUsages(resourceIds[0], resourceIds[1], resourceIds[2]);
  }
}

export function getUsages(resourceType, resourceIds, groupby, fromDate, toDate) {
  return function (dispatch) {
    dispatch({
      type: types.GET_USAGES
    });

    const apiEndpoint = getApiEndpoint(resourceType, resourceIds);
    const params = {
      params: {
        summary: (groupby == "summary"),
        monthly: (groupby == "monthly"),
        fromDate: fromDate,
        toDate: toDate
      }
    };

    const config = {...apiUtils.getConfig(), ...params};
    
    axios.get(apiEndpoint, config)
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_USAGES_SUCCESS,
          payload: res.data.usage
        });
      } else {
        dispatch({
          type: types.GET_USAGES_FAILURE,
          error: apiUtils.getResponseError(res)
        });
      }
    }).catch(err => {
      dispatch({
        type: types.GET_USAGES_FAILURE,
        error: err.toString()
      });
    });
  };
}