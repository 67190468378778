import React from "react";
import {PropTypes} from "prop-types";

const Company = ({company, onEdit, onDelete}) => {
  const confirmDelete = () => {
    if (window.confirm("Are you sure you wish to delete this company?")) {
      onDelete();
    }
  }

  return (
    <div className="company card">
      <div className="link-toolbar">
        <a href="#" onClick={() => onEdit()}>
          Edit
        </a>
        <a href="#" onClick={() => confirmDelete()}>
          Delete
        </a>
      </div>

      <p className="bold resource-name">{company.name}</p>
      <p>{company.description}</p>
    </div>
  )
}

Company.propTypes = {
  company: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Company;