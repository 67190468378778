import React from "react";
import {PropTypes} from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core"
import { 
  faPlus,
  faEdit,
  faTrash,
  faUserSlash,
  faUserCheck,
  faFileUpload,
  faFilter
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faPlus,
  faEdit,
  faTrash,
  faUserSlash,
  faUserCheck,
  faFileUpload,
  faFilter
);

const Icon = ({icon}) => {
  return (
    <FontAwesomeIcon icon={icon} />
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired
};

export default Icon;