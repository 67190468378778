const BASE_URL = process.env.API_URL;
const API = `${BASE_URL}/api/v1`;
const ADMIN_API = `${BASE_URL}/admin/api/v1`;

export const VERSION = `${API}/version`;

export const AUTHENTICATION = `${BASE_URL}/oauth/token`;
export const GET_CURRENT_USER = `${API}/user/me`;
export const PASSWORD_RESET_EMAIL = `${BASE_URL}/password/reset`;

export const ASSOCIATIONS = `${ADMIN_API}/association`;

export function companies(associationId) {
  return `${ASSOCIATIONS}/${associationId}/company`;
}

export function users(associationId, companyId) {
  return `${ASSOCIATIONS}/${associationId}/company/${companyId}/user`;
}

export const ROLES = `${ADMIN_API}/roles`;

export function availableRoles(userId) {
  return `${ROLES}/${userId}/available`;
}

export function currentRoles(userId) {
  return `${ROLES}/${userId}/current`;
}

export const AVAILABLE_FIELDS = `${ADMIN_API}/fields`;

export function fields(resourceType, resourceId) {
  return `${ADMIN_API}/${resourceType}/${resourceId}/fields`;
}

export const AVAILABLE_FEATURES = `${ADMIN_API}/feature`;

export function features(resourceType, resourceId) {
  return `${ADMIN_API}/${resourceType}/${resourceId}/feature`;
}

export function grants(resourceType, resourceId) {
  return `${ADMIN_API}/${resourceType}/${resourceId}/grant`;
}

export function associationUsages(associationId) {
  return `${ADMIN_API}/association/${associationId}/usage`;
}

export function companyUsages(associationId, companyId) {
  return `${ADMIN_API}/association/${associationId}/company/${companyId}/usage`;
}

export function userUsages(associationId, companyId, userId) {
  return `${ADMIN_API}/association/${associationId}/company/${companyId}/user/${userId}/usage`;
}