export const backgroundColors = [
  "#126C71",
  "#333366",
  "#22313f",
  "#277796",
  "#78A9B4"
];

export const bar = {
  accessOptions: {
    title: {
      display: true,
      text: "Access Count",
    }
  },
  rowOptions: {
    title: {
      display: true,
      text: "Row Count",
    }
  }
};

export const stackedbar = {
  accessOptions: {
    title: {
      display: true,
      text: "Access Count",
    },
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }]
    }
  },
  rowOptions: {
    title: {
      display: true,
      text: "Row Count",
    },
    scales: {
      xAxes: [{ stacked: true}],
      yAxes: [{ stacked: true}]
    }
  }
}

export const line = {
  accessOptions: {
    title: {
      display: true,
      text: "Access Count"
    }
  },
  rowOptions: {
    title: {
      display: true,
      text: "Row Count"
    }
  }
};

export const pie = {
  accessOptions: {
    title: {
      display: true,
      text: "Access Count",
    }
  },
  rowOptions: {
    title: {
      display: true,
      text: "Row Count"
    }
  }
};