import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/associationActions";
import * as companyActions from "../../actions/companyActions";
import {Grid, Row, Col} from "react-bootstrap";
import AssociationForm from "../AssociationForm";
import Association from "../Association";
import CompaniesList from "../CompaniesList";
import Loader from "../Loader";
import FieldsContainer from "./FieldsContainer";
import FeaturesContainer from "./FeaturesContainer";
import GrantsContainer from "./GrantsContainer";
import UsagesContainer from "./UsagesContainer";

export class AssociationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      association: {...props.association},
      associationId: this.props.match.params.associationId
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.actions.getAssociation(this.state.associationId);
    this.props.companyActions.getCompanies(this.state.associationId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.association) {
      this.setState({
        association: {...nextProps.association}
      });
    }
  }

  toggleEditAssociation = () => {
    this.setState({editingAssociation: !this.state.editingAssociation});
  }

  deleteAssociation = () => {
    this.props.actions.deleteAssociation(this.state.association).then(() => {
      this.props.history.push(`/associations`);
    });
  }

  createCompany = () => {
    this.props.companyActions.clearCompany();
    this.props.history.push(`/associations/${this.state.associationId}/companies/new`);
  }

  editCompany = (companyId) => {
    this.props.history.push(`/associations/${this.state.associationId}/companies/${companyId}`);
  }

  deleteCompany = (company) => {
    if (window.confirm("Are you sure you wish to delete this company?")) {
      this.props.companyActions.deleteCompany(this.state.associationId, company)
      .then(() => {
        this.props.companyActions.getCompanies(this.state.associationId);
      });
    }
  }

  render() {
    return (
      <div className="association-container resource-container">
        <Grid fluid>
          <Row className="section">
            <Col xs={12} md={4}>
              <h2 className="bold">Association</h2>
              {this.props.loadingAssociations.isLoading ? (
                <Loader status={this.props.loadingAssociations.status} />
              ) : (
                <div>
                  {this.state.editingAssociation ? (
                    <AssociationForm 
                      actions={this.props.actions}
                      association={this.props.association} 
                      onCancel={this.toggleEditAssociation.bind(this)} />
                  ) : (
                    <Association
                      association={this.props.association}
                      onEdit={this.toggleEditAssociation.bind(this)}
                      onDelete={this.deleteAssociation.bind(this)} />
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              {this.props.loadingCompanies.isLoading ? (
                <Loader status={this.props.loadingCompanies.status} />
              ) : (
                <CompaniesList
                  companies={this.props.companies} 
                  onCreate={this.createCompany.bind(this)}
                  onEdit={this.editCompany.bind(this)}
                  onDelete={this.deleteCompany.bind(this)} />
              )}
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <UsagesContainer
                resourceType="association"
                resourceIds={[this.state.associationId]} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FeaturesContainer
                resourceType="association"
                resourceId={this.state.associationId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <GrantsContainer
                resourceType="association"
                resourceId={this.state.associationId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FieldsContainer
                resourceType="association"
                resourceId={this.state.associationId} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

AssociationContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  companyActions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired
    })
  }),
  loadingAssociations: PropTypes.object.isRequired,
  loadingCompanies: PropTypes.object.isRequired,
  association: PropTypes.object.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
  return {
    loadingAssociations: state.loading.associations,
    loadingCompanies: state.loading.companies,
    association: state.association,
    companies: state.companies
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationContainer));