import initialState from "./initialState";
import { 
  GET_GRANTS,
  GET_GRANTS_SUCCESS,
  GET_GRANTS_FAILURE
 } from "../constants/actionTypes";

export default function usersReducer(state = initialState.grants, action) {
  switch (action.type) {
    case GET_GRANTS:
    case GET_GRANTS_FAILURE:
      return [];

    case GET_GRANTS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
