import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/grantActions";
import * as featureActions from "../../actions/featureActions";
import {Row, Col, ButtonToolbar} from "react-bootstrap";
import GrantsList from "../GrantsList";
import GrantForm from "../GrantForm";
import IconButton from "../IconButton";
import Loader from "../Loader";

export class GrantsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newGrant: false,
      resourceType: "",
      resourceId: ""
    };
  }

  componentWillMount() {
    this.props.featureActions.getAvailableFeatures();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resourceId != this.state.resourceId || 
      nextProps.resourceType != this.state.resourceType) {
      this.setState({
        resourceType: nextProps.resourceType,
        resourceId: nextProps.resourceId
      }, () => {
        if (this.state.resourceId && this.state.resourceType) {
          this.fetchGrants();
        }
      }); 
    }

    if (nextProps.grants) {
      this.setState({
        newGrant: false
      });
    }
  }

  fetchGrants = () => {
    this.props.actions.getGrants(
      this.state.resourceType,
      this.state.resourceId
    );
  }

  onNewGrant = () => {
    this.setState({newGrant: true}); 
  }

  onCancelNewGrant = () => {
    this.setState({newGrant: false});
  }

  onDelete = (grant) => {
    event.preventDefault();

    this.props.actions.deleteGrant(this.props.resourceType, this.props.resourceId, grant)
    .then(() => {
      this.fetchGrants();
    });
  }

  onSave = (newGrant) => {
    event.preventDefault();

    this.props.actions.saveGrant(this.props.resourceType, this.props.resourceId, newGrant)
    .then(() => {
      this.fetchGrants();
    });
  }

  render() {
    return (
      <div className="grants-container">
        <Row>
          <Col xs={12}>
            <h3 className="bold">Grants</h3>
          </Col>
        </Row>
        {this.props.loading.isLoading ? (
          <Row>
            <Col xs={12}>
              <Loader status={this.props.loading.status} />
            </Col>
          </Row>
        ) : (
          <div>
            <Row>
              <Col xs={12}>
                { !this.state.newGrant ? (
                  <ButtonToolbar className="actions">
                    <IconButton 
                        onClick={this.onNewGrant}
                        icon="plus"
                        btnText="New Grant" />
                  </ButtonToolbar>
                ) : null }
              </Col>
            </Row>
            <Row>
              { this.state.newGrant ? (
                <Col xs={12} md={4}>
                  <GrantForm 
                    resourceId={this.props.resourceId}
                    availableFeatures={this.props.availableFeatures}
                    onSave={this.onSave.bind(this)}
                    onCancel={this.onCancelNewGrant.bind(this)} />
                </Col>
              ) : null }
              <Col xs={12} md={this.state.newGrant ? 8 : 12}>
                { this.props.grants.length > 0 ? (
                  <GrantsList
                    grants={this.props.grants}
                    onDelete={this.onDelete.bind(this)} />
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

GrantsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  featureActions: PropTypes.object.isRequired,
  grants: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.object.isRequired,
  availableFeatures: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.grants,
    grants: state.grants,
    availableFeatures: state.availableFeatures,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    featureActions: bindActionCreators(featureActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrantsContainer)