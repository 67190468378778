import React from "react";
import {PropTypes} from "prop-types";
import {Form, FormGroup, Col, FormControl, ButtonToolbar, Button} from "react-bootstrap";

export class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {...props.company}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company) {
      this.setState({company: {...nextProps.company}})
    }
  }

  handleChange = (event) => {
    const field = event.target.name;
    const company = this.state.company;
    company[field] = event.target.value;
    return this.setState({company: company});
  }

  onSave = (event) => {
    event.preventDefault();

    if (this.state.company.extid) {
      this.props.actions.updateCompany(this.props.associationId, this.state.company)
      .then(() => {
        this.props.onCancel();
      });
    } else {
      this.props.actions.createCompany(this.props.associationId, this.state.company)
      .then(() => {
        this.props.onCancel();
      });
    }
  }

  render() {
    return (
      <Form className="company-form card" horizontal onSubmit={this.onSave.bind(this)}>
        <FormGroup controlId="formName">
          <Col xs={12}>
            Name
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text"
              placeholder=""
              name="name"
              defaultValue={this.state.company.name} 
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formDescription">
          <Col xs={12}>
            Description
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text" 
              placeholder="" 
              name="description" 
              defaultValue={this.state.company.description}
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

CompanyForm.propTypes = {
  actions: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  associationId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CompanyForm;