import initialState from "./initialState";
import { 
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
  SAVE_FEATURE_SUCCESS,
  SAVE_FEATURE_FAILURE,
} from "../constants/actionTypes";

export default function featuresReducer(state = initialState.features, action) {
  switch (action.type) {
    case GET_FEATURES:
      return [...initialState.features];

    case GET_FEATURES_SUCCESS:
    case SAVE_FEATURE_SUCCESS:
      return action.payload;
    
    case GET_FEATURES_FAILURE:
    case SAVE_FEATURE_FAILURE:
      return state;

    default:
      return state;
  }
}
