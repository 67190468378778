import initialState from "./initialState";
import { 
  GET_AVAILABLE_ROLES,
  GET_AVAILABLE_ROLES_SUCCESS,
  LOGOUT
 } from "../constants/actionTypes";

export default function availableRolesReducer(state = initialState.availableRoles, action) {
  switch (action.type) {
    case GET_AVAILABLE_ROLES:
    case LOGOUT:
      return [];

    case GET_AVAILABLE_ROLES_SUCCESS:
      return action.payload.roles;

    default:
      return state;
  }
}
