import React from "react";
import {PropTypes} from "prop-types";

const Loader = ({status}) => {
  return (
    <div className="loader center">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>

      { status != null && status.length > 0 ? (
        <div className="bold">
          {status}
        </div>
      ) : (
        null
      )}

    </div>
  )
}

Loader.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Loader;