import React from "react";
import {PropTypes} from "prop-types";
import {Label} from "react-bootstrap";

const User = ({user, onEdit, onSendPasswordResetEmail}) => {
  const prettyPrintRoles = {
    ROLE_ADMIN: "ADMIN",
    ROLE_DELADMIN: "DELEGATED ADMIN",
    ROLE_USER: "USER"
  }

  const roles = user.roles.map((role) => {
    return (
      <Label key={role} bsStyle="success">
        {prettyPrintRoles[role]}
      </Label>
    )
  });

  return (
    <div className="user card">
      <div className="link-toolbar">
        <a href="#" onClick={() => onEdit()}>
          Edit
        </a>
      </div>

      <table>
        <tbody>
          <tr>
            <td className="bold">Username:</td>
            <td>{user.userInfo.username}</td>
          </tr>

          <tr>
            <td className="bold">Name:</td>
            <td>{user.userInfo.fullName}</td>
          </tr>

          <tr>
            <td className="bold">IM:</td>
            <td>{user.userInfo.im}</td>
          </tr>

          <tr>
            <td className="bold">Phone:</td>
            <td>{user.userInfo.phone}</td>
          </tr>

          <tr>
            <td className="bold">Roles:</td>
            <td>{roles}</td>
          </tr>
        </tbody>
      </table>

      <div className="link-toolbar">
        <a href="#" onClick={() => onSendPasswordResetEmail()}>
          Send Password Reset Email
        </a>
      </div>
    </div>
  )
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSendPasswordResetEmail: PropTypes.func.isRequired
};

export default User;