import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";
import initialState from "../reducers/initialState";

function listAsArray(list) {
  return list.split(",").filter(x => x.length > 0);
}

function listsAsArrays(features) {
  features.forEach((feature) => {
    if (feature.hasOwnProperty("authorizedServiceEvents") && typeof feature.authorizedServiceEvents === "string") {
      feature.authorizedServiceEvents = listAsArray(feature.authorizedServiceEvents)
    }

    if (feature.hasOwnProperty("authorizedUserEvents") && typeof feature.authorizedUserEvents === "string") {
      feature.authorizedUserEvents = listAsArray(feature.authorizedUserEvents);
    }
  });

  return features;
}

function listsAsStrings(feature) {
  feature.authorizedServiceEvents = feature.authorizedServiceEvents.join(",");
  feature.authorizedUserEvents = feature.authorizedUserEvents.join(",");
  return feature;
}

export function getAvailableFeatures() {
  return function (dispatch) {
    dispatch({
      type: types.GET_AVAILABLE_FIELDS
    });

    return axios
    .get(`${api.AVAILABLE_FEATURES}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_AVAILABLE_FEATURES_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_AVAILABLE_FEATURES_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_AVAILABLE_FEATURES_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function getFeatures(resourceType, resourceId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_FEATURES
    });

    axios.get(api.features(resourceType, resourceId), apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        let transformedResponse = [...initialState.features];
        if (res.data.length > 0) {
          transformedResponse = listsAsArrays(res.data)
        }

        dispatch({
          type: types.GET_FEATURES_SUCCESS,
          payload: transformedResponse
        });
      } else {
        dispatch({
          type: types.GET_FEATURES_FAILURE,
          error: apiUtils.getResponseError(res)
        });
      }
    }).catch(err => {
      dispatch({
        type: types.GET_FEATURES_FAILURE,
        error: err.toString()
      });
    });
  };
}

export function saveFeature(resourceType, resourceId, feature) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_FEATURE
    });

    let transformedFeature = listsAsStrings(feature);
    return axios.post(
      `${api.features(resourceType, resourceId)}?checkConflicting=true`,
      transformedFeature,
      apiUtils.getConfig()
    )
    .then(res => {
      if (res.status == 200) {
        let transformedResponse = [...initialState.features];
        if (res.data.length > 0) {
          transformedResponse = listsAsArrays(res.data)
        }

        dispatch({
          type: types.SAVE_FEATURE_SUCCESS,
          payload: transformedResponse
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_FEATURE_FAILURE,
          payload: feature,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_FEATURE_FAILURE,
        payload: feature,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function deleteFeature(resourceType, resourceId, feature) {
  return function (dispatch) {
    dispatch({
      type: types.DELETE_FEATURE
    });

    const params = {
      data: {
        extId: resourceId,
        activeFrom: feature.activeFrom,
        activeTo: feature.activeTo
      }
    };
    const config = {...apiUtils.getConfig(), ...params};

    return axios.delete(api.features(resourceType, resourceId), config)
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.DELETE_FEATURE_SUCCESS,
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.DELETE_FEATURE_FAILURE,
          payload: feature,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.DELETE_FEATURE_FAILURE,
        payload: feature,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}