import React from "react";
import {PropTypes} from "prop-types";

const Association = ({association, onEdit, onDelete}) => {
  const confirmDelete = () => {
    if (window.confirm("Are you sure you wish to delete this association?")) {
      onDelete();
    }
  }

  return (
    <div className="association card">
      <div className="link-toolbar">
        <a href="#" onClick={() => onEdit()}>
          Edit
        </a>
        <a href="#" onClick={() => confirmDelete()}>
          Delete
        </a>
      </div>

      <p className="bold resource-name">{association.name}</p>
      <p>{association.description}</p>
    </div>
  )
}

Association.propTypes = {
  association: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Association;