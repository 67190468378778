import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/companyActions";
import * as userActions from "../../actions/userActions";
import {Grid, Row, Col} from "react-bootstrap";
import CompanyForm from "../CompanyForm";
import Company from "../Company";
import UsersList from "../UsersList";
import Loader from "../Loader";
import FieldsContainer from "./FieldsContainer";
import FeaturesContainer from "./FeaturesContainer";
import GrantsContainer from "./GrantsContainer";
import UsagesContainer from "./UsagesContainer";

export class CompanyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {...props.company},
      associationId: this.props.match.params.associationId,
      companyId: this.props.match.params.companyId
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.actions.getCompany(this.state.associationId, this.state.companyId);
    this.props.userActions.getUsers(this.state.associationId, this.state.companyId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company) {
      this.setState({
        company: {...nextProps.company}
      });
    }
  }

  onBack = () => {
    this.props.history.push(`/associations/${this.state.associationId}`)
  }

  toggleEditCompany = () => {
    this.setState({editingCompany: !this.state.editingCompany});
  }

  deleteCompany = () => {
    this.props.actions.deleteCompany(
      this.state.associationId,
      this.state.company
    )
    .then(() => {
      this.props.history.push(`/associations/${this.state.associationId}/companies`);
    });
  }

  createUser = () => {
    this.props.userActions.clearUser();
    this.props.history.push(`/associations/${this.state.associationId}/companies/${this.state.companyId}/users/new`);
  }

  editUser = (userId) => {
    this.props.history.push(`/associations/${this.state.associationId}/companies/${this.state.companyId}/users/${userId}`);
  }

  disableUser = (user) => {
    if (window.confirm("Are you sure you wish to disable this user?")) {
      this.props.userActions.deleteUser(this.state.associationId, this.state.companyId, user)
      .then(() => {
        this.props.userActions.getUsers(this.state.associationId, this.state.companyId);
      });
    }
  }

  enableUser = (user) => {
    const enabledUser = {
      ...user, 
      userInfo: {
        ...user.userInfo,
        enabled: true
      }
    };

    this.props.userActions.updateUser(this.state.associationId, this.state.companyId, enabledUser)
    .then(() => {
      this.props.userActions.getUsers(this.state.associationId, this.state.companyId);
    });
  }

  uploadUsers = (csv) => {
    alert("Coming Soon " + csv.base64);
  }

  render() {
    return (
      <div className="company-container resource-container">
        <Grid fluid>
          <Row className="section">
            <Col xs={12}>
              <div className="link-toolbar">
                <a href="#" onClick={() => this.onBack()}>
                  Back to Association
                </a>
              </div>
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12} md={4}>
              <h2 className="bold">Company</h2>
              {this.props.loadingCompanies.isLoading ? (
                <Loader status={this.props.loadingCompanies.status} />
              ) : (
                <div>
                  {this.state.editingCompany ? (
                    <CompanyForm 
                      actions={this.props.actions}
                      company={this.props.company} 
                      associationId={this.state.associationId}
                      onCancel={this.toggleEditCompany.bind(this)} />
                  ): (
                    <Company
                      company={this.props.company}
                      onEdit={this.toggleEditCompany.bind(this)}
                      onDelete={this.deleteCompany.bind(this)} />
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              {this.props.loadingUsers.isLoading ? (
                <Loader status={this.props.loadingUsers.status} />
              ) : (
                <UsersList
                  users={this.props.users} 
                  onCreate={this.createUser.bind(this)}
                  onEdit={this.editUser.bind(this)}
                  onDisable={this.disableUser.bind(this)}
                  onEnable={this.enableUser.bind(this)}
                  onUpload={this.uploadUsers.bind(this)} />
              )}
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <UsagesContainer
                resourceType="company"
                resourceIds={[this.state.associationId, this.state.companyId]} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FeaturesContainer
                resourceType="company"
                resourceId={this.state.companyId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <GrantsContainer
                resourceType="company"
                resourceId={this.state.companyId} />
            </Col>
          </Row>
          <Row className="section">
            <Col xs={12}>
              <FieldsContainer
                resourceType="company"
                resourceId={this.state.companyId} />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

CompanyContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired
    })
  }),
  loadingCompanies: PropTypes.object.isRequired,
  loadingUsers: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
  return {
    loadingCompanies: state.loading.companies,
    loadingUsers: state.loading.users,
    company: state.company,
    users: state.users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyContainer));