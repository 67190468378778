import React from "react";
import {PropTypes} from "prop-types";
import {Table, ButtonToolbar} from "react-bootstrap";
import IconButton from "./IconButton";
import moment from "moment";

const GrantsList = ({grants, onDelete}) => {
  let grantRows = grants.map((grant, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ButtonToolbar>
            <IconButton 
              onClick={() => onDelete(grant)}
              icon="trash"
              tooltipText="Delete" />
          </ButtonToolbar>
        </td>
        <td>{grant.serviceEvent}</td>
        <td>{grant.accessCount}</td>
        <td>{grant.rowCount}</td>
        <td>{grant.grantPeriod}</td>
        <td>{moment(grant.validFrom).format("YYYY-MM-DD HH:mm:ss")}</td>
        <td>{moment(grant.validUntil).format("YYYY-MM-DD HH:mm:ss")}</td>
        <td>{moment(grant.renewUntil).format("YYYY-MM-DD HH:mm:ss")}</td>
      </tr>
    )
  });

  return (
    <div className="grants-list">
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Actions</th>
            <th>Service Event</th>
            <th>Access Count</th>
            <th>Row Count</th>
            <th>Period</th>
            <th>Valid From</th>
            <th>Valid To</th>
            <th>Renew Until</th>
          </tr>
        </thead>
        <tbody>
          {grantRows}
        </tbody>
      </Table>
    </div>
  );
}

GrantsList.propTypes = {
  grants: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired
};

export default GrantsList;