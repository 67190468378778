import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/associationActions";
import {Grid, Row, Col} from "react-bootstrap";
import AssociationForm from "../AssociationForm";
import Loader from "../Loader";

export class NewAssociationContainer extends React.Component {
  goToAssociations = () => {
    this.props.history.push(`/associations`);
  }

  render() {
    return (
      <div className="new-association-container resource-container">
        {this.props.loading.isLoading ? (
          <Loader status={this.props.loading.status} />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={12} md={6}>
                <h2>New Association</h2>
                <AssociationForm 
                    actions={this.props.actions}
                    association={this.props.association} 
                    onCancel={this.goToAssociations.bind(this)} />
              </Col>
            </Row>
          </Grid>  
        )}
      </div>
    );
  }
}

NewAssociationContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loading: PropTypes.object.isRequired,
  association: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.associations,
    association: state.association
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAssociationContainer));