/* eslint import/no-named-as-default: 0 */

import { Route, Switch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import HeaderNavbar from "./HeaderNavbar";
import Footer from "./Footer";
import TermsContainer from "./containers/TermsContainer";
import Alerts from "./Alerts"
import LoginContainer from "./containers/LoginContainer";
import UserContainer from "./containers/UserContainer";
import NewUserContainer from "./containers/NewUserContainer";
import CompanyContainer from "./containers/CompanyContainer";
import NewCompanyContainer from "./containers/NewCompanyContainer";
import AssociationsContainer from "./containers/AssociationsContainer";
import AssociationContainer from "./containers/AssociationContainer";
import NewAssociationContainer from "./containers/NewAssociationContainer";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  render() {
    return (
      <div id="react-root">
        <div id="wrapper" className="full-height">
          <HeaderNavbar />
          <Alerts />

          <Switch>
            <Route exact path="/" component={LoginContainer} />
            <Route path="/login" component={LoginContainer} />
            <Route path="/terms" component={TermsContainer} />
            <PrivateRoute path="/associations/:associationId/companies/:companyId/users/new" component={NewUserContainer} />
            <PrivateRoute path="/associations/:associationId/companies/:companyId/users/:userId" component={UserContainer} />
            <PrivateRoute path="/associations/:associationId/companies/new" component={NewCompanyContainer} />
            <PrivateRoute path="/associations/:associationId/companies/:companyId" component={CompanyContainer} />
            <PrivateRoute path="/associations/new" component={NewAssociationContainer} />
            <PrivateRoute path="/associations/:associationId" component={AssociationContainer} />
            <PrivateRoute path="/associations" component={AssociationsContainer} />
            
            <Route component={LoginContainer}/>
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
