import initialState from "./initialState";
import { 
  GET_USAGES,
  GET_USAGES_SUCCESS,
  GET_USAGES_FAILURE,
} from "../constants/actionTypes";

export default function usagesReducer(state = initialState.usages, action) {
  switch (action.type) {
    case GET_USAGES:
      return [...initialState.usages];

    case GET_USAGES_SUCCESS:
      return action.payload;
    
    case GET_USAGES_FAILURE:
      return state;

    default:
      return state;
  }
}
