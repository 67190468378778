import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/companyActions";
import {Grid, Row, Col} from "react-bootstrap";
import CompanyForm from "../CompanyForm";
import Loader from "../Loader";

export class NewCompanyContainer extends React.Component {
  goToCompanies = () => {
    this.props.history.push(`/associations/${this.props.match.params.associationId}/companies`);
  }

  render() {
    return (
      <div className="new-company-container resource-container">
        {this.props.loading.isLoading ? (
          <Loader status={this.props.loading.status} />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={12} md={6}>
                <h2>New Company</h2>
                <CompanyForm 
                  actions={this.props.actions}
                  company={this.props.company} 
                  associationId={this.props.match.params.associationId}
                  onCancel={this.goToCompanies.bind(this)} />
              </Col>
            </Row>
          </Grid>  
        )}
      </div>
    );
  }
}

NewCompanyContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired
    })
  }),
  loading: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading,
    company: state.company
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCompanyContainer));