import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function getVersion() {
  return function(dispatch) {
    axios.get(api.VERSION, apiUtils.getConfig())
    .then(res => {
      return dispatch({
        type: types.GET_VERSION,
        payload: res.data
      });
    });
  }
}