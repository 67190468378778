import React from "react";
import {PropTypes} from "prop-types";
import {Form, FormGroup, Row, Col, ButtonToolbar, Button, Tab, Nav, NavItem} from "react-bootstrap";
import { Checkbox } from "antd";

export class FieldsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      fields: {...props.fields}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fields) {
      this.setState({
        editing: false,
        fields: {...nextProps.fields}
      })
    }
  }

  handleCheckChange = (checkedList, type) => {
    return this.setState({
      editing: true,
      fields: {
        ...this.state.fields,
        [type]: checkedList
      }
    });
  }

  onSave = () => {
    this.props.onSave({...this.state.fields});
  }

  render() {
    return (
      <Form className="fields-form card" onSubmit={this.onSave.bind(this)}>
        <FormGroup>
          <Tab.Container id="fields-tabs" defaultActiveKey="enriched">
            <Row>
              <Col xs={4}>
                <Nav bsStyle="pills" stacked>
                  <NavItem eventKey="enriched">Enriched</NavItem>
                  <NavItem eventKey="exportable">Exportable</NavItem>
                  <NavItem eventKey="result">Result</NavItem>
                </Nav>
              </Col>
              <Col xs={8}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="enriched">
                    <Checkbox.Group 
                      options={this.props.availableFields}
                      value={this.state.fields.enrichedFieldNames} 
                      onChange={(e) => this.handleCheckChange(e, "enrichedFieldNames")} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="exportable">
                    <Checkbox.Group 
                      options={this.props.availableFields}
                      value={this.state.fields.exportableFieldNames} 
                      onChange={(e) => this.handleCheckChange(e, "exportableFieldNames")} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="result">
                    <Checkbox.Group 
                      options={this.props.availableFields}
                      value={this.state.fields.resultFieldNames} 
                      onChange={(e) => this.handleCheckChange(e, "resultFieldNames")} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </FormGroup>

        { this.state.editing ? (
          <FormGroup className="section">
            <Col xs={12}>
              <ButtonToolbar>
                <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
                <Button bsStyle="primary" type="submit">Save</Button>
              </ButtonToolbar>
            </Col>
          </FormGroup>
        ) : null}
      </Form>
    );
  }
}

FieldsForm.propTypes = {
  fields: PropTypes.object.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default FieldsForm;