import initialState from "./initialState";
import { 
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE
 } from "../constants/actionTypes";

export default function companiesReducer(state = initialState.companies, action) {
  switch (action.type) {
    case GET_COMPANIES:
    case GET_COMPANIES_FAILURE:
      return [];

    case GET_COMPANIES_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
