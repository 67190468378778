import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/userActions";
import {Grid, Row, Col} from "react-bootstrap";
import UserForm from "../UserForm";
import Loader from "../Loader";

export class NewUserContainer extends React.Component {
  goToUsers = () => {
    this.props.history.push(`/associations/${this.props.match.params.associationId}/companies/${this.props.match.params.companyId}`);
  }

  render() {
    return (
      <div className="new-user-container resource-container">
        {this.props.loading.isLoading ? (
          <Loader status={this.props.loading.status} />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={12} md={6}>
                <h2>New User</h2>
                <UserForm 
                    actions={this.props.actions}
                    user={this.props.user}
                    availableRoles={this.props.availableRoles}
                    associationId={this.props.match.params.associationId}
                    companyId={this.props.match.params.companyId}
                    onCancel={this.goToUsers.bind(this)} />
              </Col>
            </Row>
          </Grid>  
        )}
      </div>
    );
  }
}

NewUserContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      associationId: PropTypes.string.isRequired,
      companyId: PropTypes.string.isRequired
    })
  }),
  loading: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.users,
    user: state.user,
    availableRoles: state.availableRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserContainer));