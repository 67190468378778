import initialState from "./initialState";
import { 
  CLEAR_USER,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
} from "../constants/actionTypes";

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case CLEAR_USER:
    case GET_USER:
      return {...initialState.user};

    case GET_USER_SUCCESS:
    case SAVE_USER_SUCCESS:
      return action.payload;
    
    case GET_USER_FAILURE:
    case SAVE_USER_FAILURE:
      return state;

    default:
      return state;
  }
}
