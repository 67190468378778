import initialState from "./initialState";
import { 
  CLEAR_ASSOCIATION,
  GET_ASSOCIATION,
  GET_ASSOCIATION_SUCCESS,
  GET_ASSOCIATION_FAILURE,
  SAVE_ASSOCIATION_SUCCESS,
  SAVE_ASSOCIATION_FAILURE
} from "../constants/actionTypes";

export default function associationReducer(state = initialState.association, action) {
  switch (action.type) {
    case CLEAR_ASSOCIATION:
    case GET_ASSOCIATION:
      return {...initialState.association};

    case GET_ASSOCIATION_SUCCESS:
    case SAVE_ASSOCIATION_SUCCESS:
      return action.payload;
    
    case GET_ASSOCIATION_FAILURE:
    case SAVE_ASSOCIATION_FAILURE:
      return state;

    default:
      return state;
  }
}
