import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/usageActions";
import {Row, Col, ButtonToolbar, Tab, Nav, NavItem} from "react-bootstrap";
import Loader from "../Loader";
import IconButton from "../IconButton";
import UsagesList from "../UsagesList";
import UsagesForm from "../UsagesForm";
import {Pie, Doughnut, Bar, Line} from "react-chartjs-2";
import * as visualizations from "../../constants/visualizations";
import update from "immutability-helper";
import moment from "moment";

export class UsagesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "",
      resourceIds: [],
      groupby: "summary",
      fromDate: moment().startOf("year").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      showForm: false,
      chartData: {
        accessCount: {},
        rowCount: {}
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resourceIds != this.state.resourceIds || 
      nextProps.resourceType != this.state.resourceType) {
      this.setState({
        resourceType: nextProps.resourceType,
        resourceIds: nextProps.resourceIds
      }, () => {
        if (this.state.resourceId && this.state.resourceType) {
          this.fetchUsages();
        }
      }); 
    }

    let labels;
    if (nextProps.usages != this.props.usages) {
      if (this.state.groupby == "summary") {
        labels = nextProps.usages.map(x => x.serviceEvent);

        this.setState({chartData: 
          {
            accessCount: {
              datasets: [{
                data: nextProps.usages.map(x => x.accessCount),
                label: "Count"
              }],
              labels: labels
            },
            rowCount: {
              datasets: [{
                data: nextProps.usages.map(x => x.rowCount),
                label: "Count"
              }],
              labels: labels
            }
          }
        });
      } else {
        if (this.state.groupby == "monthly") {
          // Get the unique month/year
          labels = Array.from(
            new Set(
              nextProps.usages.map(x => `${x.activityMonth}-${x.activityYear}`)
            )
          )

        } else {
          // Get the unique activity dates
          labels = Array.from(new Set(nextProps.usages.map(x => x.activityDate)));
        }

        this.setState({chartData: 
          {
            accessCount: {
              datasets: [                
                {
                  label: "ENRICH",
                  data: nextProps.usages.filter(x => x.serviceEvent == "ENRICH").map(x => x.accessCount),
                  backgroundColor: visualizations.backgroundColors[0]
                },
                {
                  label: "EXPORT",
                  data: nextProps.usages.filter(x => x.serviceEvent == "EXPORT").map(x => x.accessCount),
                  backgroundColor: visualizations.backgroundColors[1]
                },
                {
                  label: "PREVIEW",
                  data: nextProps.usages.filter(x => x.serviceEvent == "PREVIEW").map(x => x.accessCount),
                  backgroundColor: visualizations.backgroundColors[2]
                },
                {
                  label: "QUERY",
                  data: nextProps.usages.filter(x => x.serviceEvent == "QUERY").map(x => x.accessCount),
                  backgroundColor: visualizations.backgroundColors[3]
                },
                {
                  label: "ENHANCE",
                  data: nextProps.usages.filter(x => x.serviceEvent == "ENHANCE").map(x => x.accessCount),
                  backgroundColor: visualizations.backgroundColors[4]
                }
              ],
              labels: labels
            },
            rowCount: {
              datasets: [                
                {
                  label: "ENRICH",
                  data: nextProps.usages.filter(x => x.serviceEvent == "ENRICH").map(x => x.rowCount),
                  backgroundColor: visualizations.backgroundColors[0]
                },
                {
                  label: "EXPORT",
                  data: nextProps.usages.filter(x => x.serviceEvent == "EXPORT").map(x => x.rowCount),
                  backgroundColor: visualizations.backgroundColors[1]
                },
                {
                  label: "PREVIEW",
                  data: nextProps.usages.filter(x => x.serviceEvent == "PREVIEW").map(x => x.rowCount),
                  backgroundColor: visualizations.backgroundColors[2]
                },
                {
                  label: "QUERY",
                  data: nextProps.usages.filter(x => x.serviceEvent == "QUERY").map(x => x.rowCount),
                  backgroundColor: visualizations.backgroundColors[3]
                },
                {
                  label: "ENHANCE",
                  data: nextProps.usages.filter(x => x.serviceEvent == "ENHANCE").map(x => x.rowCount),
                  backgroundColor: visualizations.backgroundColors[4]
                }
              ],
              labels: labels
            }
          }
        });
      }
    }
  }

  fetchUsages = () => {
    this.props.actions.getUsages(
      this.state.resourceType,
      this.state.resourceIds,
      this.state.groupby,
      this.state.fromDate,
      this.state.toDate
    );
  }

  onSearch = (groupby, fromDate, toDate) => {
    event.preventDefault();

    this.setState({
      groupby: groupby,
      fromDate: fromDate,
      toDate: toDate,
      showForm: false
    }, () => {
      this.fetchUsages();
    });
  }
  
  onShowForm = () => {
    this.setState({showForm: true});
  }

  onCancel = () => {
    this.setState({showForm: false});
  }

  onTabSelect = (key) => {
    switch (key) {
      case "bar":
      case "line": {
        let newState = update(this.state.chartData, {
          accessCount: {
            datasets: {
              [0]: {
                backgroundColor: { $set: visualizations.backgroundColors[0] }
              }
            }
          },
          rowCount: {
            datasets: {
              [0]: {
                backgroundColor: { $set: visualizations.backgroundColors[0] }
              }
            }
          }
        });

        this.setState({chartData: newState});        
        break;
      }
      
      case "pie":
      case "donut": {
        let newState = update(this.state.chartData, {
          accessCount: {
            datasets: {
              [0]: {
                backgroundColor: { $set: visualizations.backgroundColors }
              }
            }
          },
          rowCount: {
            datasets: {
              [0]: {
                backgroundColor: { $set: visualizations.backgroundColors }
              }
            }
          }
        });

        this.setState({chartData: newState});        
        break;
      }
    }
  }

  render() {
    return (
      <div className="usages-container">
        <Row>
          <Col xs={12}>
            <h3 className="bold">Usages</h3>
          </Col>
        </Row>
        {this.props.loading.isLoading ? (
          <Row>
            <Col xs={12}>
              <Loader status={this.props.loading.status} />
            </Col>
          </Row>
        ) : (
          <div>
            <Row>
              <Col xs={12}>
                { !this.state.showForm ? (
                  <ButtonToolbar className="actions">
                    <IconButton 
                      onClick={this.onShowForm}
                      icon="filter"
                      btnText="Filter Usages" />
                  </ButtonToolbar>
                ) : null }
              </Col>
            </Row>
            <Row>
              { this.state.showForm ? (
                <Col xs={12} md={4}>
                  <UsagesForm
                    groupby={this.state.groupby}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    onSearch={this.onSearch.bind(this)}
                    onCancel={this.onCancel.bind(this)} />
                </Col>
              ) : null }
              <Col xs={12} md={this.state.showForm ? 8 : 12}>
                { this.props.usages.length > 0 && this.state.groupby == "summary" ? (
                  <Tab.Container id="usages-tabs" defaultActiveKey="table" onSelect={this.onTabSelect}>
                    <Row>
                      <Col xs={12}>
                        <Nav bsStyle="pills">
                          <NavItem eventKey="table">Table</NavItem>
                          <NavItem eventKey="bar">Bar</NavItem>
                          <NavItem eventKey="line">Line</NavItem>
                          <NavItem eventKey="pie">Pie</NavItem>
                          <NavItem eventKey="donut">Donut</NavItem>
                        </Nav>
                      </Col>
                      <Col xs={12}>
                        <Tab.Content animation>
                          <Tab.Pane eventKey={"table"} title="Table">
                            <UsagesList
                              usages={this.props.usages}
                              groupby={this.state.groupby} />
                          </Tab.Pane>
                          <Tab.Pane eventKey={"bar"} title="Bar">
                            <Col xs={12} md={6}>
                              <Bar 
                                data={this.state.chartData.accessCount}
                                options={visualizations.bar.accessOptions} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Bar 
                                data={this.state.chartData.rowCount}
                                options={visualizations.bar.rowOptions} />
                            </Col>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"line"} title="Line">
                            <Col xs={12} md={6}>
                              <Line 
                                data={this.state.chartData.accessCount}
                                options={visualizations.line.accessOptions} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Line 
                                data={this.state.chartData.rowCount}
                                options={visualizations.line.rowOptions} />
                            </Col>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"pie"} title="Pie">
                            <Row>
                              <Col xs={12} md={6}>
                                <Pie 
                                  data={this.state.chartData.accessCount}
                                  options={visualizations.pie.accessOptions} />
                              </Col>
                              <Col xs={12} md={6}>
                                <Pie 
                                  data={this.state.chartData.rowCount}
                                  options={visualizations.pie.rowOptions} />
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"donut"} title="Donut">
                            <Col xs={12} md={6}>
                              <Doughnut 
                                data={this.state.chartData.accessCount}
                                options={visualizations.pie.accessOptions} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Doughnut 
                                data={this.state.chartData.rowCount}
                                options={visualizations.pie.rowOptions} />
                            </Col>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : null }

                { this.props.usages.length > 0 && (this.state.groupby == "monthly" || this.state.groupby == "") ? (
                  <Tab.Container id="usages-tabs" defaultActiveKey="table" onSelect={this.onTabSelect}>
                    <Row>
                      <Col xs={12}>
                        <Nav bsStyle="pills">
                          <NavItem eventKey="table">Table</NavItem>
                          <NavItem eventKey="stackedbar">Stacked Bar</NavItem>
                          <NavItem eventKey="line">Line</NavItem>
                        </Nav>
                      </Col>
                      <Col xs={12}>
                        <Tab.Content animation>
                          <Tab.Pane eventKey={"table"} title="Table">
                            <UsagesList
                              usages={this.props.usages}
                              groupby={this.state.groupby} />
                          </Tab.Pane>
                          <Tab.Pane eventKey={"stackedbar"} title="StackedBar">
                            <Col xs={12} md={6}>
                              <Bar 
                                data={this.state.chartData.accessCount}
                                options={visualizations.stackedbar.accessOptions} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Bar 
                                data={this.state.chartData.rowCount}
                                options={visualizations.stackedbar.rowOptions} />
                            </Col>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"line"} title="Line">
                            <Col xs={12} md={6}>
                              <Line 
                                data={this.state.chartData.accessCount}
                                options={visualizations.line.accessOptions} />
                            </Col>
                            <Col xs={12} md={6}>
                              <Line 
                                data={this.state.chartData.rowCount}
                                options={visualizations.line.rowOptions} />
                            </Col>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : null }
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

UsagesContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  usages: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.usages,
    usages: state.usages
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsagesContainer)