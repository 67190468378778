import initialState from "./initialState";
import { 
  GET_GRANT,
  GET_GRANT_SUCCESS,
  GET_GRANT_FAILURE,
  SAVE_GRANT_SUCCESS,
  SAVE_GRANT_FAILURE,
} from "../constants/actionTypes";

export default function grantReducer(state = initialState.grant, action) {
  switch (action.type) {
    case GET_GRANT:
      return {...initialState.grant};

    case GET_GRANT_SUCCESS:
    case SAVE_GRANT_SUCCESS:
      return action.payload;
    
    case GET_GRANT_FAILURE:
    case SAVE_GRANT_FAILURE:
      return state;

    default:
      return state;
  }
}
