import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/associationActions";
import {Grid, Row, Col, Table, ButtonToolbar} from "react-bootstrap";
import Loader from "../Loader";
import IconButton from "../IconButton";

export class AssociationsContainer extends React.Component {
  componentDidMount() {
    this.props.actions.getAssociations();
  }

  createAssociation = () => {
    this.props.actions.clearAssociation();
    this.props.history.push(`/associations/new`);
  }

  editAssociation = (associationId) => {
    this.props.history.push(`/associations/${associationId}`);
  }

  deleteAssociation = (association) => {
    if (window.confirm("Are you sure you wish to delete this association?")) {
      this.props.actions.deleteAssociation(association).then(() => {
        this.props.actions.getAssociations();
      });
    }
  }

  render() {
    let associations = this.props.associations.map((association, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            <ButtonToolbar>
              <IconButton 
                onClick={() => this.editAssociation(association.extid)}
                icon="edit"
                tooltipText="View/Edit" />

              <IconButton 
                onClick={() => this.deleteAssociation(association)}
                icon="trash"
                tooltipText="Delete" />
            </ButtonToolbar>
          </td>
          <td>{association.name}</td>
          <td>{association.description}</td>
        </tr>
      )
    });

    return (
      <div className="associations-container resource-container">
        {this.props.loading.isLoading ? (
          <Loader status={this.props.loading.status} />
        ) : (
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <h3>Associations</h3>

                <ButtonToolbar className="actions">
                  <IconButton 
                    onClick={this.createAssociation}
                    icon="plus"
                    btnText="New Association" />
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Actions</th>
                      <th>Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {associations}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>          
        )}

      </div>
    );
  }
}

AssociationsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  loading: PropTypes.object.isRequired,
  associations: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.loading.associations,
    associations: state.associations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationsContainer);
