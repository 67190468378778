import { combineReducers } from "redux";
import version from "./versionReducer";
import loading from "./loadingReducer";
import session from "./sessionReducer";
import currentUser from "./currentUserReducer";
import availableRoles from "./availableRolesReducer";
import alerts from "./alertsReducer";
import users from "./usersReducer";
import user from "./userReducer";
import companies from "./companiesReducer";
import company from "./companyReducer";
import associations from "./associationsReducer";
import association from "./associationReducer";
import availableFields from "./availableFieldsReducer";
import fields from "./fieldsReducer";
import availableFeatures from "./availableFeaturesReducer";
import features from "./featuresReducer";
import grants from "./grantsReducer";
import grant from "./grantReducer";
import usages from "./usagesReducer";
import { routerReducer } from "react-router-redux";

const rootReducer = combineReducers({
  version,
  loading,
  session,
  currentUser,
  availableRoles,
  alerts,
  users,
  user,
  companies,
  company,
  associations,
  association,
  availableFields,
  fields,
  availableFeatures,
  features,
  grants,
  grant,
  usages,
  routing: routerReducer
});

export default rootReducer;
