import React from "react";
import {PropTypes} from "prop-types";
import {Row, Col, Table, ButtonToolbar} from "react-bootstrap";
import IconButton from "./IconButton";

const CompaniesList = ({companies, onCreate, onEdit, onDelete}) => {
  let companyRows = companies.map((company, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ButtonToolbar>
            <IconButton 
              onClick={() => onEdit(company.extid)}
              icon="edit"
              tooltipText="View/Edit" />
            <IconButton 
              onClick={() => onDelete(company)}
              icon="trash"
              tooltipText="Delete" />
          </ButtonToolbar>
        </td>
        <td>{company.name}</td>
        <td>{company.description}</td>
      </tr>
    )
  });

  return (
    <div className="companies-list">
      <Row>
        <Col xs={12}>
          <h3 className="bold">Companies</h3>

          <ButtonToolbar className="actions">
            <IconButton 
                onClick={() => onCreate()}
                icon="plus"
                btnText="New Company" />
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Table striped bordered condensed hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Actions</th>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {companyRows}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default CompaniesList;