import React from "react";
import {PropTypes} from "prop-types";
import {Form, FormGroup, Col, FormControl, ButtonToolbar, Button} from "react-bootstrap";

export class AssociationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      association: {...props.association}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.association) {
      this.setState({association: {...nextProps.association}})
    }
  }

  handleChange = (event) => {
    const field = event.target.name;
    const association = this.state.association;
    association[field] = event.target.value;
    return this.setState({association: association});
  }

  onSave = (event) => {
    event.preventDefault();

    if (this.state.association.extid) {
      this.props.actions.updateAssociation(this.state.association).then(() => {
        this.props.onCancel();
      });
    } else {
      this.props.actions.createAssociation(this.state.association).then(() => {
        this.props.onCancel();
      });
    }
  }

  render() {
    return (
      <Form className="association-form card" horizontal onSubmit={this.onSave.bind(this)}>
        <FormGroup controlId="formName">
          <Col xs={12}>
            Name
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text"
              placeholder=""
              name="name"
              defaultValue={this.state.association.name} 
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formDescription">
          <Col xs={12}>
            Description
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text" 
              placeholder="" 
              name="description" 
              defaultValue={this.state.association.description}
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

AssociationForm.propTypes = {
  actions: PropTypes.object.isRequired,
  association: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AssociationForm;