import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function getCurrentUser() {
  return function(dispatch) {
    return axios
      .get(api.GET_CURRENT_USER, apiUtils.getConfig())
      .then(res => {
        dispatch({
          type: types.SET_CURRENT_USER,
          payload: res.data
        });

        return Promise.resolve(res.data);
      });
  }
}

export function clearCurrentUser() {
  return function(dispatch) {
    return dispatch({
      type: types.CLEAR_CURRENT_USER
    });
  }
}
