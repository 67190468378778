import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function clearAssociation() {
  return function (dispatch) {
    dispatch({
      type: types.CLEAR_ASSOCIATION
    });
  }
}

export function getAssociations() {
  return function (dispatch) {
    dispatch({
      type: types.GET_ASSOCIATIONS
    });

    return axios.get(api.ASSOCIATIONS, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_ASSOCIATIONS_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_ASSOCIATIONS_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    }).catch(err => {
      dispatch({
        type: types.GET_ASSOCIATIONS_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  };
}

export function getAssociation(associationId) {
  return function (dispatch) {
    dispatch({
      type: types.GET_ASSOCIATION
    });

    return axios
    .get(`${api.ASSOCIATIONS}/${associationId}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.GET_ASSOCIATION_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.GET_ASSOCIATION_FAILURE,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.GET_ASSOCIATION_FAILURE,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function createAssociation(association) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_ASSOCIATION
    });

    return axios.post(`${api.ASSOCIATIONS}`, association, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_ASSOCIATION_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_ASSOCIATION_FAILURE,
          payload: association,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_ASSOCIATION_FAILURE,
        payload: association,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function updateAssociation(association) {
  return function (dispatch) {
    dispatch({
      type: types.SAVE_ASSOCIATION
    });

    return axios.put(`${api.ASSOCIATIONS}/${association.extid}`, association, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.SAVE_ASSOCIATION_SUCCESS,
          payload: res.data
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.SAVE_ASSOCIATION_FAILURE,
          payload: association,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.SAVE_ASSOCIATION_FAILURE,
        payload: association,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}

export function deleteAssociation(association) {
  return function (dispatch) {
    dispatch({
      type: types.DELETE_ASSOCIATION
    });

    return axios.delete(`${api.ASSOCIATIONS}/${association.extid}`, apiUtils.getConfig())
    .then(res => {
      if (res.status == 200) {
        dispatch({
          type: types.DELETE_ASSOCIATION_SUCCESS,
        });

        return Promise.resolve();
      } else {
        dispatch({
          type: types.DELETE_ASSOCIATION_FAILURE,
          payload: association,
          error: apiUtils.getResponseError(res)
        });

        return Promise.reject();
      }
    })
    .catch(err => {
      dispatch({
        type: types.DELETE_ASSOCIATION_FAILURE,
        payload: association,
        error: err.toString()
      });

      return Promise.reject();
    });
  }
}