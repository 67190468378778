import React from "react";
import {PropTypes} from "prop-types";
import {Table, Label} from "react-bootstrap";

const UsagesList = ({usages, groupby}) => {
  let usageRows = usages.map((usage, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        
        { !groupby ? (
          <td>
            { usage.hasOwnProperty("activityDate") ? (
              usage.activityDate.toString()
            ) : null }
          </td>
        ) : null }

        { groupby == "monthly" ? (
          <td>
            { usage.hasOwnProperty("activityMonth") ? (
              usage.activityMonth.toString()
            ) : null }
          </td>
        ) : null }

        { groupby == "monthly" ? (
          <td>
            { usage.hasOwnProperty("activityYear") ? (
              usage.activityYear.toString()
            ) : null }
          </td>
        ) : null }
        
        <td>
          <Label bsStyle="success">
            {usage.serviceEvent}
          </Label>
        </td>
        <td>{usage.accessCount}</td>
        <td>{usage.rowCount}</td>
      </tr>
    )
  });

  return (
    <div className="usages-list">
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th>#</th>
            { !groupby ? (
              <th>Activity Date</th>
            ) : null }
            { groupby == "monthly" ? (
              <th>Activity Month</th>
            ) : null }
            { groupby == "monthly" ? (
              <th>Activity Year</th>
            ) : null }
            <th>Service Event</th>
            <th>Access Count</th>
            <th>Row Count</th>
          </tr>
        </thead>
        <tbody>
          {usageRows}
        </tbody>
      </Table>
    </div>
  );
}

UsagesList.propTypes = {
  usages: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupby: PropTypes.string.isRequired
};

export default UsagesList;