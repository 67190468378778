import initialState from "./initialState";
import { 
  GET_ASSOCIATIONS,
  GET_ASSOCIATIONS_SUCCESS,
  GET_ASSOCIATIONS_FAILURE,
  GET_ASSOCIATION,
  GET_ASSOCIATION_SUCCESS,
  GET_ASSOCIATION_FAILURE,
  SAVE_ASSOCIATION,
  SAVE_ASSOCIATION_SUCCESS,
  SAVE_ASSOCIATION_FAILURE,
  DELETE_ASSOCIATION,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_FAILURE,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  SAVE_COMPANY,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAILURE,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAILURE,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_AVAILABLE_ROLES,
  GET_AVAILABLE_ROLES_SUCCESS,
  GET_AVAILABLE_ROLES_FAILURE,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_FAILURE,
  GET_AVAILABLE_FIELDS,
  GET_AVAILABLE_FIELDS_SUCCESS,
  GET_AVAILABLE_FIELDS_FAILURE,
  GET_FIELDS,
  GET_FIELDS_SUCCESS,
  GET_FIELDS_FAILURE,
  SAVE_FIELDS,
  SAVE_FIELDS_SUCCESS,
  SAVE_FIELDS_FAILURE,
  GET_AVAILABLE_FEATURES,
  GET_AVAILABLE_FEATURES_SUCCESS,
  GET_AVAILABLE_FEATURES_FAILURE,
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
  SAVE_FEATURE,
  SAVE_FEATURE_SUCCESS,
  SAVE_FEATURE_FAILURE,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAILURE,
  GET_GRANTS,
  GET_GRANTS_SUCCESS,
  GET_GRANTS_FAILURE,
  GET_GRANT,
  GET_GRANT_SUCCESS,
  GET_GRANT_FAILURE,
  SAVE_GRANT,
  SAVE_GRANT_SUCCESS,
  SAVE_GRANT_FAILURE,
  DELETE_GRANT,
  DELETE_GRANT_SUCCESS,
  DELETE_GRANT_FAILURE,
  GET_USAGES,
  GET_USAGES_SUCCESS,
  GET_USAGES_FAILURE,
} from "../constants/actionTypes";

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    
    // Association(s)
    case GET_ASSOCIATIONS:
      return {...state,
        associations: {...state.associations, isLoading: true, status: "Fetching associations..."}
      };
      
    case GET_ASSOCIATION:
      return {...state,
        associations: {...state.associations, isLoading: true, status: "Fetching association..."}
      };

    case SAVE_ASSOCIATION:
      return {...state,
        associations: {...state.associations, isLoading: true, status: "Saving association..."}
      };

    case DELETE_ASSOCIATION:
      return {...state,
        associations: {...state.associations, isLoading: true, status: "Deleting association..."}
      };
    
    case GET_ASSOCIATIONS_SUCCESS:
    case GET_ASSOCIATIONS_FAILURE:
    case GET_ASSOCIATION_SUCCESS:
    case GET_ASSOCIATION_FAILURE:
    case SAVE_ASSOCIATION_SUCCESS:
    case SAVE_ASSOCIATION_FAILURE:
    case DELETE_ASSOCIATION_SUCCESS:
    case DELETE_ASSOCIATION_FAILURE:
      return {...state, 
        associations: {...state.associations, isLoading: false, status: ""}
      };

    // Company(ies)
    case GET_COMPANIES:
      return {...state,
        companies: {...state.companies, isLoading: true, status: "Fetching companies..."}
      };
      
    case GET_COMPANY:
      return {...state,
        companies: {...state.companies, isLoading: true, status: "Fetching company..."}
      };

    case SAVE_COMPANY:
      return {...state,
        companies: {...state.companies, isLoading: true, status: "Saving company..."}
      };

    case DELETE_COMPANY:
      return {...state,
        companies: {...state.companies, isLoading: true, status: "Deleting company..."}
      };

    case GET_COMPANIES_SUCCESS:
    case GET_COMPANIES_FAILURE:
    case GET_COMPANY_SUCCESS:
    case GET_COMPANY_FAILURE:
    case SAVE_COMPANY_SUCCESS:
    case SAVE_COMPANY_FAILURE:
    case DELETE_COMPANY_SUCCESS:
    case DELETE_COMPANY_FAILURE:
      return {...state,
        companies: {...state.companies, isLoading: false, status: ""}
      };

    // Users
    case GET_USERS:
      return {...state,
        users: {...state.users, isLoading: true, status: "Fetching users..."}
      };
      
    case GET_USER:
      return {...state,
        users: {...state.users, isLoading: true, status: "Fetching user..."}
      };

    case SAVE_USER:
      return {...state,
        users: {...state.users, isLoading: true, status: "Saving user..."}
      };

    case DELETE_USER:
      return {...state,
        users: {...state.users, isLoading: true, status: "Deleting user..."}
      };

    case GET_USERS_SUCCESS:
    case GET_USERS_FAILURE:
    case GET_USER_SUCCESS:
    case GET_USER_FAILURE:
    case SAVE_USER_SUCCESS:
    case SAVE_USER_FAILURE:
    case DELETE_USER_SUCCESS:
    case DELETE_USER_FAILURE:
      return {...state,
        users: {...state.users, isLoading: false, status: ""}
      };

    case GET_AVAILABLE_ROLES:
      return {...state,
        users: {...state.users, isLoading: true, status: "Fetching available roles..."}
      };

    case GET_AVAILABLE_ROLES_SUCCESS:
    case GET_AVAILABLE_ROLES_FAILURE:
      return {...state,
        users: {...state.users, isLoading: false, status: ""}
      };

    case SEND_PASSWORD_RESET:
      return {...state,
        users: {...state.users, isLoading: true, status: "Sending password reset email..."}
      };

    case SEND_PASSWORD_RESET_SUCCESS:
    case SEND_PASSWORD_RESET_FAILURE:
      return {...state,
        users: {...state.users, isLoading: false, status: ""}
      };

    // Fields
    case GET_AVAILABLE_FIELDS:
      return {...state,
        availableFields: {...state.availableFields, isLoading: true, status: "Fetching available fields..."}
      };

    case GET_FIELDS:
      return {...state,
        fields: {...state.fields, isLoading: true, status: "Fetching fields..."}
      };

    case SAVE_FIELDS:
      return {...state,
        fields: {...state.fields, isLoading: true, status: "Saving fields..."}
      };

    case GET_AVAILABLE_FIELDS_SUCCESS:
    case GET_AVAILABLE_FIELDS_FAILURE:
      return {...state,
        availableFields: {...state.availableFields, isLoading: false, status: ""}
      };

    case GET_FIELDS_SUCCESS:
    case GET_FIELDS_FAILURE:
    case SAVE_FIELDS_SUCCESS:
    case SAVE_FIELDS_FAILURE:
      return {...state,
        fields: {...state.fields, isLoading: false, status: ""}
      };

    // Features
    case GET_AVAILABLE_FEATURES:
      return {...state,
        availableFeatures: {...state.availableFeatures, isLoading: true, status: "Fetching available features..."}
      };
    
    case GET_FEATURES:
      return {...state,
        features: {...state.features, isLoading: true, status: "Fetching features..."}
      };

    case SAVE_FEATURE:
      return {...state,
        features: {...state.features, isLoading: true, status: "Saving feature..."}
      };
      
    case DELETE_FEATURE:
      return {...state,
        features: {...state.features, isLoading: true, status: "Deleting feature..."}
      };
      
    case GET_AVAILABLE_FEATURES_SUCCESS:
    case GET_AVAILABLE_FEATURES_FAILURE:
      return {...state,
        availableFeatures: {...state.availableFeatures, isLoading: false, status: ""}
      };

    case GET_FEATURES_SUCCESS:
    case GET_FEATURES_FAILURE:
    case SAVE_FEATURE_SUCCESS:
    case SAVE_FEATURE_FAILURE:
    case DELETE_FEATURE_SUCCESS:
    case DELETE_FEATURE_FAILURE:
      return {...state,
        features: {...state.features, isLoading: false, status: ""}
      };

    // Grants
    case GET_GRANTS:
      return {...state,
        grants: {...state.grants, isLoading: true, status: "Fetching grants..."}
      };

    case GET_GRANT:
      return {...state,
        grants: {...state.grants, isLoading: true, status: "Fetching grant..."}
      };

    case SAVE_GRANT:
      return {...state,
        grants: {...state.grants, isLoading: true, status: "Saving grant..."}
      };

    case DELETE_GRANT:
      return {...state,
        grants: {...state.grants, isLoading: true, status: "Deleting grant..."}
      };

    case GET_GRANTS_SUCCESS:
    case GET_GRANTS_FAILURE:
    case GET_GRANT_SUCCESS:
    case GET_GRANT_FAILURE:
    case SAVE_GRANT_SUCCESS:
    case SAVE_GRANT_FAILURE:
    case DELETE_GRANT_SUCCESS:
    case DELETE_GRANT_FAILURE:
      return {...state,
        grants: {...state.grants, isLoading: false, status: ""}
      };

    // Usages
    case GET_USAGES:
      return {...state,
        usages: {...state.usages, isLoading: true, status: "Fetching usages..."}
      };
    
    case GET_USAGES_SUCCESS:
    case GET_USAGES_FAILURE:
      return {...state,
        usages: {...state.usages, isLoading: false, status: ""}
      };
      
    default:
      return state;
  }
}
