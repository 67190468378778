import {LOGIN_SUCCESS, LOGOUT} from "../constants/actionTypes";
import initialState from "./initialState";

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case LOGOUT:
      return {...state, auth: !!localStorage.accessToken};

    default:
      return state;
  }
}
