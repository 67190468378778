import initialState from "./initialState";
import { 
  GET_AVAILABLE_FEATURES,
  GET_AVAILABLE_FEATURES_SUCCESS,
  GET_AVAILABLE_FEATURES_FAILURE
 } from "../constants/actionTypes";

export default function availableFieldsReducer(state = initialState.availableFeatures, action) {
  switch (action.type) {
    case GET_AVAILABLE_FEATURES:
    case GET_AVAILABLE_FEATURES_FAILURE:
      return {...initialState.availableFeatures};

    case GET_AVAILABLE_FEATURES_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
