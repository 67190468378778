import React from "react";
import {PropTypes} from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../actions/alertActions";
import AlertDismissable from "./AlertDismissable";

export class Alerts extends React.Component {
  clearAlert = (index) => {
    this.props.actions.clearAlert(index);
  }

  render() {
    let alerts = this.props.alerts.map((alert, index) => {
      return (
        <AlertDismissable key={index}
          alertStyle={alert.style}
          message={alert.message}
          index={index}
          onDismiss={this.clearAlert.bind(this)} />
      );
    });

    return (
      <div className="alerts">
        {alerts}
      </div>
    );
  }
}

Alerts.propTypes = {
  actions: PropTypes.object.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
  return {
    alerts: state.alerts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alerts);