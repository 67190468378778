import initialState from "./initialState";
import { 
  CLEAR_COMPANY,
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_FAILURE
} from "../constants/actionTypes";

export default function companyReducer(state = initialState.company, action) {
  switch (action.type) {
    case CLEAR_COMPANY:
    case GET_COMPANY:
      return {...initialState.company};

    case GET_COMPANY_SUCCESS:
    case SAVE_COMPANY_SUCCESS:
      return action.payload;
    
    case GET_COMPANY_FAILURE:
    case SAVE_COMPANY_FAILURE:
      return state;

    default:
      return state;
  }
}
