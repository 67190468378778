import React from "react";
import {PropTypes} from "prop-types";
import {Form, FormGroup, Col, FormControl, ButtonToolbar, Button, ToggleButtonGroup, ToggleButton} from "react-bootstrap";

export class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {...props.user}
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({user: {...nextProps.user}})
    }
  }

  handleChange = (event) => {
    const field = event.target.name;
    const userInfo = {...this.state.user.userInfo};
    userInfo[field] = event.target.value;
    
    if (field == "username") {
      userInfo["email"] = event.target.value
    }

    return this.setState({
      user: {
        ...this.state.user,
        userInfo: userInfo
      }
    });
  }

  handleRoleChange = (event) => {
    const roles = event;
    
    return this.setState({
      user: {
        ...this.state.user,
        roles: roles
      }
    });
  }

  onSave = (event) => {
    event.preventDefault();

    if (this.state.user.userInfo.extuid) {
      this.props.actions.updateUser(this.props.associationId, this.props.companyId, this.state.user)
      .then(() => {
        this.props.onCancel();
      });
    } else {
      this.props.actions.createUser(this.props.associationId, this.props.companyId, this.state.user)
      .then(() => {
        this.props.onCancel();
      });
    }
  }

  render() {
    return (
      <Form className="user-form card" horizontal onSubmit={this.onSave.bind(this)}>
        <FormGroup controlId="formUsername">
          <Col xs={12}>
            Username/Email
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text"
              placeholder=""
              name="username"
              defaultValue={this.state.user.userInfo.username} 
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formFullName">
          <Col xs={12}>
            Full Name
          </Col>
          <Col xs={12}>
            <FormControl 
              type="text" 
              placeholder="" 
              name="fullName" 
              defaultValue={this.state.user.userInfo.fullName}
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formIm">
          <Col xs={12}>
            IM
          </Col>
          <Col xs={12}>
            <FormControl
              type="text"
              placeholder=""
              name="im"
              defaultValue={this.state.user.userInfo.im}
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup controlId="formPhone">
          <Col xs={12}>
            Phone
          </Col>
          <Col xs={12}>
            <FormControl
              type="text"
              placeholder=""
              name="phone"
              defaultValue={this.state.user.userInfo.phone}
              onChange={this.handleChange.bind(this)} />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            Roles
          </Col>
          <Col xs={12}>
            <ButtonToolbar>
              <ToggleButtonGroup type="checkbox" defaultValue={this.state.user.roles} onChange={this.handleRoleChange}>
                <ToggleButton bsStyle="success" value={"ROLE_ADMIN"} disabled={!this.props.availableRoles.includes("ROLE_ADMIN")}>
                  Admin
                </ToggleButton>
                <ToggleButton bsStyle="success" value={"ROLE_DELADMIN"} disabled={!this.props.availableRoles.includes("ROLE_DELADMIN")}>
                  Delegated Admin
                </ToggleButton>
                <ToggleButton bsStyle="success" value={"ROLE_USER"} disabled={!this.props.availableRoles.includes("ROLE_USER")}>
                  User
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col xs={12}>
            <ButtonToolbar>
              <Button bsStyle="success" onClick={() => this.props.onCancel()}>Cancel</Button>
              <Button bsStyle="primary" type="submit">Save</Button>
            </ButtonToolbar>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

UserForm.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  associationId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UserForm;