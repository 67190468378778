import initialState from "./initialState";
import { 
  GET_ASSOCIATIONS,
  GET_ASSOCIATIONS_SUCCESS,
  GET_ASSOCIATIONS_FAILURE
 } from "../constants/actionTypes";

export default function associationsReducer(state = initialState.associations, action) {
  switch (action.type) {
    case GET_ASSOCIATIONS:
    case GET_ASSOCIATIONS_FAILURE:
      return [];

    case GET_ASSOCIATIONS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
}
